import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Grid, Box, Typography, CircularProgress, IconButton, LinearProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlayArrowSharpIcon from '@mui/icons-material/PlayArrowSharp';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import { PiSoundcloudLogoDuotone } from "react-icons/pi";
import Bullet from '../components/Bullet';
import moment from 'moment';
import { NowPlayingContext } from '../components/NowPlayingProvider';

const token = process.env.REACT_APP_WSSR_API_TOKEN;

const formatDuration = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  return `${hours > 0 ? `${hours}h ` : ''}${minutes}m`;
};

const FeedTrackList = () => {
  const { directory } = useParams();
  const navigate = useNavigate();
  const [tracks, setTracks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingTrackId, setLoadingTrackId] = useState(null);
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(10);
  const [totalDuration, setTotalDuration] = useState(0);
  const [trackCount, setTrackCount] = useState(0);
  const [scale, setScale] = useState(1);
  const [topImage, setTopImage] = useState(null);


  const {
    playSpecificTrack,
    currentTrackId,
    isPlaying,
    setIsPlaying,
    nowPlayingData,
  } = useContext(NowPlayingContext);

  useEffect(() => {
    const fetchTracks = async () => {
      try {
        const endpoint = `https://broadcast.wssr.stream/api/station/1/files/list?sort=timestamp&sortOrder=DESC&currentDirectory=${encodeURIComponent(directory)}`;
        const response = await axios.get(endpoint, { headers: { Authorization: token } });
  
        const coverTrack = response.data.find(track => track.path_short === 'cover.png');
        let topImageUrl = null;
  
        if (coverTrack && coverTrack.links?.download) {
          try {
            const imageResponse = await axios.get(
              `https://broadcast.wssr.stream${coverTrack.links.download}`,
              {
                headers: { Authorization: token },
                responseType: 'blob'
              }
            );
            topImageUrl = URL.createObjectURL(imageResponse.data); // Create blob URL for secure display
          } catch (imageError) {
            console.error('Error fetching cover.png image:', imageError);
          }
        }
  
        const filteredTracks = response.data.filter(track => track.path_short !== 'cover.png');
        const duration = filteredTracks.reduce((acc, track) => acc + (track.media?.length || 0), 0);
  
        const tracksWithFullArtUrl = filteredTracks.map((track) => ({
          ...track,
          fullArtUrl: track.media?.art
            ? `https://broadcast.wssr.stream${track.media.art}`
            : null,
        }));
  
        setTracks(tracksWithFullArtUrl);
        setTopImage(topImageUrl || tracksWithFullArtUrl[0]?.fullArtUrl); // Prioritize cover.png, fallback to first track
        setTrackCount(filteredTracks.length);
        setTotalDuration(duration);
      } catch (error) {
        console.error('Error fetching tracks:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchTracks();
  }, [directory]);
  
  

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 100) {
        const newScale = Math.max(0.9, 1 - (scrollTop - 100) / 500);
        setScale(newScale);
      } else {
        setScale(1);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handlePlay = async (track, index) => {
    if (isPlaying && currentTrackId === track.path) {
      // Pause playback if the same track is already playing
      setIsPlaying(false);
      return;
    }
  
    // If the same track is paused, resume playback from the current position
    if (currentTrackId === track.path && !isPlaying) {
      setIsPlaying(true); // Resume playback
      return;
    }
  
    // Otherwise, start playback for a new track
    setLoadingTrackId(track.path);
    const mediaUrl = track.links?.download
      ? `https://broadcast.wssr.stream${track.links.download}`
      : null;
  
    if (mediaUrl) {
      try {
        const response = await axios.get(mediaUrl, {
          headers: { Authorization: token },
          responseType: 'blob',
        });
  
        const blobUrl = URL.createObjectURL(response.data);
  
        playSpecificTrack(blobUrl, {
          title: track.media?.title || 'Unknown Title',
          artist: track.media?.artist || 'Unknown Artist',
          art: track.media?.art ? `https://broadcast.wssr.stream${track.media.art}` : null,
          duration: track.media?.length || 0,
          trackList: tracks,
          elapsed: currentTrackId === track.path ? nowPlayingData?.elapsed || 0 : 0, // Resume or start fresh
        }, track.path, index);
  
        // Cache the BLOB URL in the track object
        track.url = blobUrl;
  
        setIsPlaying(true);
      } catch (error) {
        console.error('Error fetching media file:', error);
      } finally {
        setLoadingTrackId(null);
      }
    } else {
      console.error('Invalid media URL for track:', track);
      setLoadingTrackId(null);
    }
  };
  
  
  





  const directoryShort = directory.split('/').pop();
  const firstTrack = tracks[0];

  return (
    <div>
      {loading ? (
        <Grid item xs={12} display="flex" justifyContent="center">
          <Box sx={{ marginTop: '2rem' }}>
            <CircularProgress thickness={3} />
          </Box>
        </Grid>
      ) : (
        <Box sx={{ flexGrow: 1, paddingTop: '80px' }}>
          <IconButton
            onClick={() => navigate(-1)}
            sx={{
              position: 'fixed',
              top: 80,
              left: 16,
              zIndex: 10,
              backgroundColor: '#1c232f',
              color: '#fff',
              boxShadow: '0 3px 6px rgba(0,0,0,0.1)',
              '&:hover': {
                backgroundColor: '#1c232f',
              },
            }}
          >
            <ArrowBackIcon />
          </IconButton>

          {firstTrack && (
            <Grid
              container
              spacing={3}
              sx={{
                marginBottom: '1.5rem',
                transform: `scale(${scale})`,
                transformOrigin: 'top',
                transition: 'transform 0.6s ease, margin 0.6s ease',
              }}
            >
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                  <Box
                      sx={{
                        width: '100%',
                        paddingTop: '100%',
                        position: 'relative',
                      }}
                    >
                      <img
                        src={topImage}
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          boxShadow: '5px 5px 0px 0px rgba(255,102,0,1), 10px 10px 0px 0px rgba(25,35,48,1)',
                        }}
                        alt={directoryShort || 'Art'}
                      />
                    </Box>

                  </Grid>


                  <Grid item xs={12} md={6} display="flex" justifyContent="center" flexDirection="column">
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                      {directoryShort}
                    </Typography>
                    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginTop: '1rem' }}>
                      <Typography variant="subtitle2" sx={{ color: '#999' }}>
                        {trackCount} {trackCount === 1 ? 'Track' : 'Tracks'} {Bullet} {formatDuration(totalDuration)}
                      </Typography>
                      {firstTrack && (
                        <IconButton
                        onClick={() => {
                          if (isPlaying && tracks.some(track => track.path === currentTrackId)) {
                            // Pause playback if the current track is from the current list
                            setIsPlaying(false);
                          } else {
                            if (currentTrackId && !tracks.some(track => track.path === currentTrackId)) {
                              // If a track from another list is playing, stop it and start the new list
                              setIsPlaying(false);
                              handlePlay(firstTrack, 0); // Start playback from the new list
                            } else {
                              // If no track is playing or resuming within the same list
                              handlePlay(firstTrack, 0); // Ensure playback starts correctly
                            }
                          }
                        }}
                        sx={{ color: 'primary.main' }}
                      >
                        {loadingTrackId === firstTrack.path ? (
                          <CircularProgress
                            size={40}
                            sx={{
                              color: 'primary.main',
                            }}
                          />
                        ) : isPlaying && tracks.some(track => track.path === currentTrackId) ? (
                          <PauseCircleIcon sx={{ fontSize: '4rem' }} />
                        ) : (
                          <PlayCircleIcon sx={{ fontSize: '4rem' }} />
                        )}
                      </IconButton>
                      
                      )}
                    </Box>
                  </Grid>






                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid container spacing={3} sx={{ marginBottom: '8rem' }}>
            {tracks.map((track, index) => {
              const formattedTime = moment.unix(track.timestamp).fromNow();
              return (
                <Grid item xs={12} key={index}>
                  <Grid container spacing={2}>
                    <Grid item xs={5} md={4}>
                      <Box
                        sx={{
                          width: '100%',
                          paddingTop: '100%',
                          position: 'relative',
                          
                        }}
                        onClick={() => handlePlay(track, index)}
                      >
                        {loadingTrackId === track.path && (
                          <LinearProgress
                            variant="buffer"
                            value={progress}
                            valueBuffer={buffer}
                            sx={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              zIndex: 1,
                            }}
                          />
                        )}
                        <img
                          src={track.fullArtUrl}
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                          }}
                          alt={track.media?.title || 'Track Art'}
                        />
                        {isPlaying && currentTrackId === track.path ? (
                          <EqualizerIcon
                            fontSize="large"
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              color: 'white',
                              opacity: 0.8,
                              cursor: 'auto',
                            }}
                          />
                        ) : (
                          <PlayArrowSharpIcon
                            fontSize="large"
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              color: 'white',
                              opacity: 0.8,
                              cursor: 'pointer',
                            }}
                          />
                        )}
                        {track.media?.custom_fields?.elink && (
                          <IconButton
                              component="a"
                              href={track.media.custom_fields.elink}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(event) => event.stopPropagation()} // Prevent playback on click
                              sx={{
                                clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
                                  position: 'absolute',
                                  bottom: 2,
                                  right: 2,
                                  padding: '8px',
                                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                  color: '#FF991C',
                                  borderRadius: '0%',
                                  transition: 'all 0.2s ease-in-out',
                                  '&:hover': {
                                      backgroundColor: 'rgba(0, 0, 0, 0.9)',
                                      padding: '12px',
                                      clipPath: 'polygon(0 20%, 80% 0, 100% 80%, 20% 100%)',
                                  }
                              }}
                          >
                              <PiSoundcloudLogoDuotone />
                          </IconButton>
                      )}

                      </Box>
                    </Grid>
                    <Grid item xs={7} md={8} display="flex" justifyContent="center" flexDirection="column">
                      <Typography variant="subtitle1">
                        {track.media?.title || track.path_short}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ color: '#999' }}>
                        {track.media?.artist || 'Unknown Artist'}
                      </Typography>
                      <Typography variant="subtitle2" sx={{ color: '#999' }}>
                        {formatDuration(track.media?.length || 0)} {Bullet} {formattedTime}
                      </Typography>
                      {isPlaying && currentTrackId === track.path && (
                        <Box display="flex" alignItems="center" mt={1}>
                          <EqualizerIcon sx={{ fontSize: '14px', mr: 0.5, color: 'primary.main' }} />
                          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                            Now Playing
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}
    </div>
  );
};

export default FeedTrackList;
