import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Grid,
  Box,
  Typography,
  IconButton,
  Card,
  CardContent,
  CardMedia,
  Collapse,
  Button,
  Skeleton,
  LinearProgress,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlayArrowSharpIcon from '@mui/icons-material/PlayArrowSharp';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { NowPlayingContext } from '../components/NowPlayingProvider';
import ShowTrackListHeader from '../components/ShowTrackListHeader';

const ShowTrackList = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const token = process.env.REACT_APP_WSSR_API_TOKEN;
  const {
    playSpecificTrack,
    pauseTrack,
    currentTrackId,
    isPlaying,
    setIsPlaying,
    setIsLiveStream,
    setNowPlayingData,
    setCurrentTrackIndex, // Add this
  setTrackQueue,        // Add this

  } = useContext(NowPlayingContext);

  const isNowPlaying = (episodeId) => {
    const normalizedId = normalizeTrackId(currentTrackId);
    const result = normalizedId === episodeId && isPlaying;
    //console.log('isNowPlaying Check:', {
    //  currentTrackId,
    //  normalizedId,
    //  episodeId,
    //  isPlaying,
    //  result,
    //});
    return result;
  };
  
  
  
  

  const [episodes, setEpisodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(null);
  const [trackLengths, setTrackLengths] = useState({});
  const [loadingTrackId, setLoadingTrackId] = useState(null);
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(10);

  const startLoadingAnimation = () => {
    setProgress(0);
    setBuffer(10);

    const interval = setInterval(() => {
      setProgress((prev) => Math.min(prev + Math.random() * 10, 100));
      setBuffer((prev) => Math.min(prev + Math.random() * 15, 100));
    }, 200);

    return interval;
  };

  const normalizeTrackId = (trackId) => {
    if (!trackId) return ''; // Handle null/undefined
    const parts = trackId.split('/');
    const idWithExtension = parts[parts.length - 1]; // Get the last segment
    return idWithExtension.replace('.mp3', ''); // Remove .mp3 if present
  };
  
  
  

  

  const fetchEpisodes = async () => {
    try {
      const endpoint = `https://broadcast.wssr.stream/api/station/1/public/podcast/${id}/episodes`;
      const response = await axios.get(endpoint);
      const enrichedEpisodes = response.data.map((episode, index) => ({
        ...episode,
        index, // Add track index for navigation
        art: episode.art?.startsWith('http') 
          ? episode.art 
          : `https://broadcast.wssr.stream${episode.art || ''}`, // Prefix art URL if needed
        path: episode.media?.path || '', // Ensure `path` is extracted correctly
      }));
      setEpisodes(enrichedEpisodes);
      console.log('Fetched episodes with enriched art URLs:', enrichedEpisodes); // Debug log
    } catch (error) {
      console.error('Error fetching show data:', error);
    } finally {
      setLoading(false);
    }
  };
  
  
  

  const fetchTrackLength = async (episode) => {
    try {
      const trackId = episode.playlist_media?.id;
      if (trackId) {
        const trackresponse = `https://broadcast.wssr.stream/api/station/1/file/${trackId}`;
        const response = await axios.get(trackresponse, { headers: { Authorization: token } });
        setTrackLengths((prev) => ({ ...prev, [episode.id]: response.data.length_text }));
      }
    } catch (error) {
      console.error('Error fetching track length:', error);
    }
  };

  useEffect(() => {
    fetchEpisodes();
  }, [id]);

  useEffect(() => {
    episodes.forEach((episode) => {
      if (!episode.media && episode.playlist_media?.id) {
        fetchTrackLength(episode);
      }
    });
  }, [episodes]);

  const [activeTrackId, setActiveTrackId] = useState(currentTrackId);

  useEffect(() => {
    console.log('Current track ID updated:', currentTrackId);
    setActiveTrackId(currentTrackId);
    setExpanded(currentTrackId);
  }, [currentTrackId]);
  
  useEffect(() => {
    console.log('Is Playing State:', isPlaying);
  }, [isPlaying]);
  


  useEffect(() => {
    if (currentTrackId) {
      setExpanded(currentTrackId);
    }
  }, [currentTrackId]);

  useEffect(() => {
    if (currentTrackId) {
      const element = document.getElementById(`track-${currentTrackId}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [currentTrackId]);
  
  

  const handlePlayPause = async (episode) => {
    if (currentTrackId === episode.id) {
      if (isPlaying) {
        pauseTrack();
      } else {
        setNowPlayingData((prevData) => ({
          ...prevData,
          elapsed: 0, // Reset progress on replay
        }));
        setIsPlaying(true);
      }
    } else {
      setLoadingTrackId(episode.id);
      const loadingAnimation = startLoadingAnimation();
  
      try {
        const mediaUrl = episode.links?.download;
        if (mediaUrl) {
          const response = await axios.get(mediaUrl, {
            headers: { Authorization: token },
            responseType: 'blob',
          });
  
          const blobUrl = URL.createObjectURL(response.data);
  
          playSpecificTrack(blobUrl, {
            title: episode.title || 'Unknown Title',
            artist: episode.description_short || 'Unknown Artist',
            art: episode.art?.startsWith('http')
              ? episode.art
              : `https://broadcast.wssr.stream${episode.art || ''}`, // Prefix art URL
            duration: episode.duration || 0,
            trackList: episodes.map((ep) => ({ ...ep, source: 'ShowTrackList' })), // Add source identifier
          }, episode.id, episode.index);
          
          
          
          
  
          // Set the current track index in the provider
          setCurrentTrackIndex(episode.index);
          setTrackQueue(episodes); // Save the track list for navigation
          setIsLiveStream(false);
          setIsPlaying(true);
        } else {
          console.error('Invalid media URL for episode:', episode);
        }
      } catch (error) {
        console.error('Error during playback:', error);
      } finally {
        clearInterval(loadingAnimation);
        setLoadingTrackId(null);
      }
    }
  };
  

  const handleExpandClick = (episodeId) => {
    setExpanded((prevExpanded) => (prevExpanded === episodeId ? null : episodeId));
  };

  return (
    <Box sx={{ flexGrow: 1, paddingTop: '80px', paddingBottom: '130px' }}>
      <IconButton
        onClick={() => navigate(-1)}
        sx={{
          position: 'fixed',
          top: 80,
          left: 16,
          zIndex: 10,
          backgroundColor: '#1c232f',
          color: '#fff',
          boxShadow: '0 3px 6px rgba(0,0,0,0.1)',
          '&:hover': {
            backgroundColor: '#1c232f',
          },
        }}
      >
        <ArrowBackIcon />
      </IconButton>

      {/* Header Section */}
      <ShowTrackListHeader id={id} />

      <Grid container spacing={3}>
        {(loading ? Array.from(new Array(5)) : episodes).map((episode, index) => (
          <Grid item xs={12} key={episode?.id || index} id={`track-${episode?.id}`}>
            <Card sx={{ borderRadius: 0, position: 'relative' }}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleExpandClick(episode?.id);
                }}
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  transform: expanded === episode?.id ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s',
                }}
              >
                <ExpandMoreIcon />
              </IconButton>
              <CardContent
                sx={{
                  paddingRight: '40px',
                  cursor: 'pointer',
                  paddingTop: '16px',
                }}
                onClick={() => handleExpandClick(episode?.id)}
              >
                <Box display="flex" alignItems="center" sx={{ overflow: 'hidden', width: '100%' }}>
                  <Box
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!(isPlaying && currentTrackId === episode?.id)) handlePlayPause(episode);
                    }}
                    sx={{
                      position: 'relative',
                      width: 100,
                      height: 100,
                      marginRight: 2,
                      paddingLeft: 1,
                      cursor: isPlaying && currentTrackId === episode?.id ? 'default' : 'pointer',
                    }}
                  >
                    {loadingTrackId === episode?.id && (
                      <LinearProgress
                        variant="buffer"
                        value={progress}
                        valueBuffer={buffer}
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: '8px',
                          width: '100%',
                          zIndex: 1,
                          opacity: progress >= 100 ? 0 : 1,
                          transition: 'opacity 0.3s ease-in-out',
                        }}
                      />
                    )}
                    {episode?.art ? (
                      <CardMedia
                        component="img"
                        src={episode.art}
                        alt={episode.title || 'Episode Art'}
                        sx={{
                          width: 100,
                          height: 100,
                        }}
                      />
                    ) : (
                      <Skeleton variant="rectangular" width={100} height={100} />
                    )}
                    {isNowPlaying(episode?.id) ? (
  <EqualizerIcon
    fontSize="large"
    sx={{
      position: 'absolute',
      top: '50%',
      left: '56%',
      transform: 'translate(-50%, -50%)',
      color: 'white',
      opacity: 0.8,
    }}
  />
) : (
  <PlayArrowSharpIcon
    fontSize="large"
    sx={{
      position: 'absolute',
      top: '50%',
      left: '56%',
      transform: 'translate(-50%, -50%)',
      color: 'white',
      opacity: 0.8,
    }}
  />
)}




                  </Box>
                  <Box sx={{ flex: 1 }}>
                    {episode?.title ? (
                      <Typography variant="h6" sx={{ lineHeight: 1.2 }}>
                        {episode.title}
                      </Typography>
                    ) : (
                      <Skeleton width="80%" height={20} />
                    )}
                    {episode?.description_short ? (
                      <Typography variant="body2" sx={{ color: '#999' }}>
                        {episode.description_short}
                      </Typography>
                    ) : (
                      <Skeleton width="60%" height={15} />
                    )}
                    {episode?.media?.length_text || trackLengths[episode?.id] ? (
                      <Typography variant="body2" sx={{ color: '#666', fontSize: '0.75rem' }}>
                        {episode.media?.length_text || trackLengths[episode.id]}
                      </Typography>
                    ) : (
                      <Skeleton width={50} height={20} />
                    )}
                  </Box>
                </Box>
              </CardContent>
              <Collapse in={expanded === episode?.id} timeout="auto">
                <CardContent>
  <Box>
    {episode?.title ? (
      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
        {episode.title}
      </Typography>
    ) : (
      <Skeleton width="80%" height={20} />
    )}
    {episode?.description ? (
      <Typography variant="body2" color="textSecondary">
        {episode.description}
      </Typography>
    ) : (
      <Skeleton width="90%" height={15} />
    )}
  </Box>
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 2,
    }}
  >
    <Button
  onClick={(e) => {
    e.stopPropagation();
    if (!isNowPlaying(episode?.id)) handlePlayPause(episode);
  }}
  disabled={isNowPlaying(episode?.id)} // Disable if already playing
>
  {isNowPlaying(episode?.id) ? (
    <Box display="flex" alignItems="center">
      <EqualizerIcon sx={{ fontSize: '14px', mr: 0.5, color: 'primary.main' }} />
      <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
        Now Playing
      </Typography>
    </Box>
  ) : (
    <PlayArrowSharpIcon />
  )}
</Button>



  </Box>
</CardContent>

              </Collapse>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ShowTrackList;
