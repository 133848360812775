import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import SensorsSharp from '@mui/icons-material/SensorsSharp';
import DynamicFeedSharp from '@mui/icons-material/DynamicFeedSharp';
import FilterNoneSharpIcon from '@mui/icons-material/FilterNoneSharp';
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
import EventNoteSharpIcon from '@mui/icons-material/EventNoteSharp';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useSpring, animated } from 'react-spring';

export default function LabelBottomNavigation({ onNavigate }) {
  const location = useLocation();
  const [value, setValue] = React.useState(getValueForLocation(location));
  const theme = useTheme();

  const tabRefs = React.useRef([]);
  const [indicatorStyle, api] = useSpring(() => ({
    left: 0,
    width: 0,
    boxShadow: '0px 4px 0px 1px transparent',
  }));

  React.useEffect(() => {
    const newValue = getValueForLocation(location);
    setValue(newValue);

    const index = getIndexForValue(newValue);
    if (tabRefs.current[index]) {
      const { offsetLeft, offsetWidth } = tabRefs.current[index];
      api.start({
        left: offsetLeft,
        width: offsetWidth,
        boxShadow: `0px 4px 20px 1px ${theme.palette.primary.main}`,
      });
    }
  }, [location, api]);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    // Trigger the onNavigate callback
    if (onNavigate) {
      onNavigate(); // Notify parent to collapse the AudioDrawer
    }

    const index = getIndexForValue(newValue);
    if (tabRefs.current[index]) {
      const { offsetLeft, offsetWidth } = tabRefs.current[index];
      api.start({
        left: offsetLeft,
        width: offsetWidth,
        boxShadow: `0px 4px 10px 1px ${theme.palette.primary.main}`,
      });
    }
  };

  function getValueForLocation(location) {
    if (location.pathname === '/') {
      return 'broadcast';
    } else if (location.pathname.startsWith('/feed')) {
      return 'feed';
    } else if (location.pathname === '/shows') {
      return 'shows';
    } else if (location.pathname.startsWith('/show')) {
      return 'shows';
    } else if (location.pathname === '/schedule') {
      return 'schedule';
    } else if (location.pathname === '/about') {
      return 'about';
    } else {
      return 'recents';
    }
  }

  function getIndexForValue(value) {
    switch (value) {
      case 'broadcast':
        return 0;
      case 'feed':
        return 1;
      case 'shows':
        return 2;
      case 'schedule':
        return 3;
      case 'about':
        return 4;
      default:
        return -1;
    }
  }

  return (
    <BottomNavigation className="blur-bottom-nav"
      sx={{
        backgroundColor: "#000",
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1201,
      }}
      elevation={3}
      value={value}
      onChange={handleChange}
    >
      <animated.div
        style={{
          position: 'absolute',
          top: 0,
          height: '2px',
          backgroundColor: theme.palette.primary.main,
          borderRadius: '0px',
          ...indicatorStyle,
        }}
      />
      {['broadcast', 'feed', 'shows', 'schedule', 'about'].map((val, index) => (
        <BottomNavigationAction
          key={val}
          label={
            val === 'broadcast' ? 'Broadcast' :
            val === 'feed' ? 'Feed' :
            val === 'shows' ? 'Shows' :
            val === 'schedule' ? 'Schedule' :
            'Info'
        }
          value={val}
          icon={
            val === 'broadcast' ? <SensorsSharp /> :
            val === 'feed' ? <DynamicFeedSharp /> :
            val === 'shows' ? <FilterNoneSharpIcon /> :
            val === 'schedule' ? <EventNoteSharpIcon /> :
            <InfoSharpIcon />
          }
          component={Link}
          to={`/${val === 'broadcast' ? '' : val}`}
          sx={{
            minWidth: 0,
            '& .MuiSvgIcon-root': {
              color: value === val ? theme.palette.primary.main : '#868686',
            },
          }}
          ref={el => (tabRefs.current[index] = el)}
        />
      ))}
    </BottomNavigation>
  );
}
