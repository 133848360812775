import React, { useEffect, useState, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import Home from './pages/Home';
import Feed from './pages/Feed';
import Shows from './pages/Shows';
import Schedule from './pages/Schedule';
import About from './pages/About';
import FeedTrackList from './pages/FeedTrackList';
import ShowTrackList from './pages/ShowTrackList';
import Links from './pages/Links';

import darkTheme from "./components/Theme";
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';

import ElevateAppBar from './components/TopBar';
import BottomNavigation from "./components/BottomNavigation";
import CustomAudioPlayer from './components/AudioPlayer';
import AudioDrawer from './components/AudioDrawer';
import ParticleBackground from './components/ParticleBackground';
import { FeedProvider } from './components/FeedContext';
import { SnackbarProvider } from './components/SnackbarContext';
import useScrollRestoration from './components/useScrollRestoration';
import { NowPlayingProvider, NowPlayingContext } from './components/NowPlayingProvider';
import { Box } from '@mui/material'; 
import { Rings } from 'react-loader-spinner';

// Import the new TypedText component
import TypedText from './components/TypedText';

// Initialize Google Analytics
ReactGA.initialize('G-2175L6NKN7');

function App() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // Handlers for opening and closing the drawer
  const handleDrawerOpen = () => setIsDrawerOpen(true);
  const handleDrawerClose = () => setIsDrawerOpen(false);

  return (
    <Router>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <FeedProvider>
          <SnackbarProvider>
            <NowPlayingProvider>
              <AppContent
                isDrawerOpen={isDrawerOpen}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
              />
            </NowPlayingProvider>
          </SnackbarProvider>
        </FeedProvider>
      </ThemeProvider>
    </Router>
  );
}

function AppContent({ isDrawerOpen, handleDrawerOpen, handleDrawerClose }) {
  const location = useLocation();
  const { isLoading, initialLoadComplete, nowPlayingData: nowPlaying } = useContext(NowPlayingContext);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  if (isLoading && !initialLoadComplete) {
    // Only show the spinner during the initial load
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: '#1d1d1d',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2, // Adds spacing between Rings and TypedText
          }}
        >
          <Rings
            visible={true}
            height="80"
            width="80"
            color="#ff6600"
            ariaLabel="rings-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
          <TypedText />
        </Box>
      </Box>
    );
  }

  return (
    <>
      <ScrollRestoration />
      <ElevateAppBar />
      <PageContainer>
        <ParticleBackground />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/feed" element={<Feed />} />
          <Route path="/shows" element={<Shows />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/about" element={<About />} />
          <Route path="/feed/:directory" element={<FeedTrackList />} />
          <Route path="/show/:id" element={<ShowTrackList />} />
          <Route path="/links" element={<Links />} />
          <Route path="/partners" element={<Links />} />
        </Routes>

        {/* Audio Drawer */}
        <AudioDrawer 
          open={isDrawerOpen} 
          onClose={handleDrawerClose} 
          nowPlaying={nowPlaying}
        />

        {/* Audio Player */}
        <div onClick={handleDrawerOpen}>
          <CustomAudioPlayer />
        </div>

        {/* Pass onNavigate to BottomNavigation */}
        <BottomNavigation onNavigate={handleDrawerClose} />
      </PageContainer>
    </>
  );
}

function ScrollRestoration() {
  useScrollRestoration();
  return null;
}

function PageContainer({ children }) {
  const location = useLocation();

  // Set maxWidth based on the current path
  let maxWidth;
  if (location.pathname === '/shows' || location.pathname === '/links' || location.pathname === '/partners') {
    maxWidth = 'lg';
  } else if (location.pathname === '/about') {
    maxWidth = 'md';
  } else {
    maxWidth = 'sm';
  }

  return <Container maxWidth={maxWidth}>{children}</Container>;
}

export default App;
