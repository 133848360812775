import React, { useContext, useEffect, useRef } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
//import { useSpring, animated } from 'react-spring';
import { NowPlayingContext } from './NowPlayingProvider';
import Skeleton from '@mui/material/Skeleton';
import CustomProgressBar from './CustomProgressBar';
import { useSwipeable } from 'react-swipeable';
import { useSpring, animated } from '@react-spring/web';

const AudioPlayerComponent = () => {
  const {
    nowPlayingData,
    streamUrl,
    progressColor,
    isPlaying,
    setIsPlaying,
    isLiveStream,
    isLoading, // Explicit use of isLoading from context
    setNowPlayingData,
    playPreviousTrack,
    playNextTrack,
  } = useContext(NowPlayingContext);
  

  const [animating, setAnimating] = React.useState(false);
   // Animation for title, artist, and art
  const [contentStyle, api] = useSpring(() => ({
    opacity: 1,
    x: 0,
    config: { tension: 200, friction: 25 },
  }));

  const handleSwipe = (direction) => {
    if (isLiveStream || animating) return; // Ignore swipes during live stream or animation

    setAnimating(true);
    const xValue = direction === 'left' ? -100 : 100;

    api.start({
      x: xValue,
      opacity: 0,
      onRest: () => {
        // Reset position and play track after animation
        api.start({ x: 0, opacity: 1 });
        setAnimating(false);

        if (direction === 'left') {
          playNextTrack(nowPlayingData?.trackList || []);
        } else {
          playPreviousTrack(nowPlayingData?.trackList || []);
        }
      },
    });
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleSwipe('left'),
    onSwipedRight: () => handleSwipe('right'),
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const audioRef = useRef(null);
  const duration = nowPlayingData?.duration || 1;
  const initialElapsed = nowPlayingData?.elapsed || 0;

  const slideInAnimation = useSpring({
    from: { transform: 'translateY(100%)' },
    to: { transform: 'translateY(0%)' },
    config: { tension: 200, friction: 65 },
  });

  const playbackLock = useRef(false); // Lock to prevent overlapping calls

  useEffect(() => {
    if (audioRef.current) {
      const audio = audioRef.current.audio.current;
  
      if (playbackLock.current) {
        console.log('Playback locked, skipping update...');
        return;
      }
  
      playbackLock.current = true;
  
      if (isPlaying) {
        const source = isLiveStream ? streamUrl : nowPlayingData?.url;
  
        if (audio.src !== source) {
          console.log(`Updating audio source to: ${source}`);
          audio.pause(); // Ensure playback stops during source update
  
          if (isLiveStream) {
            audio.src = ''; // Clear src to reset live position
          }
  
          audio.src = source;
  
          audio.oncanplay = () => {
            console.log('Source ready, starting playback...');
            audio
              .play()
              .then(() => {
                console.log('Playback started...');
                playbackLock.current = false; // Unlock after successful playback
              })
              .catch((err) => {
                console.error('Error playing audio:', err);
                playbackLock.current = false; // Unlock on error
              });
          };
  
          audio.onerror = () => {
            console.error(`Failed to load audio source: ${source}`);
            playbackLock.current = false; // Unlock on error
          };
        } else {
          console.log('Playing current source...');
          audio
            .play()
            .then(() => {
              console.log('Playback resumed...');
              playbackLock.current = false; // Unlock after successful playback
            })
            .catch((err) => {
              console.error('Error playing audio:', err);
              playbackLock.current = false; // Unlock on error
            });
        }
      } else {
        console.log('Pausing playback...');
        audio.pause();
  
        if (isLiveStream) {
          console.log('Clearing livestream source on pause...');
          audio.src = '';
        }
  
        playbackLock.current = false; // Unlock after pause
      }
    }
  }, [streamUrl, nowPlayingData?.url, isPlaying, isLiveStream]);
  
  

  useEffect(() => {
    const audioElement = audioRef.current?.audio?.current;
    if (audioElement && nowPlayingData?.elapsed !== undefined && !isLiveStream) {
      console.log(`Setting currentTime for ondemand to: ${nowPlayingData.elapsed}`);
      audioElement.currentTime = nowPlayingData.elapsed;
    }
  }, [nowPlayingData?.elapsed, isLiveStream]);

  useEffect(() => {
    if ('mediaSession' in navigator && nowPlayingData) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: nowPlayingData.title || 'Unknown Title',
        artist: nowPlayingData.artist || 'Unknown Artist',
        album: 'Sector Seven Radio', // Optional
        artwork: [
          { src: nowPlayingData.art || 'https://placehold.co/96x96', sizes: '96x96', type: 'image/png' },
          { src: nowPlayingData.art || 'https://placehold.co/128x128', sizes: '128x128', type: 'image/png' },
          { src: nowPlayingData.art || 'https://placehold.co/192x192', sizes: '192x192', type: 'image/png' },
          { src: nowPlayingData.art || 'https://placehold.co/256x256', sizes: '256x256', type: 'image/png' },
        ],
      });
    }
  }, [nowPlayingData]);

  

  const handlePlay = () => {
    console.log(`Play triggered. Live stream: ${isLiveStream}`);
  
    // Stop SSE when playing an on-demand track
    if (!isLiveStream) {
      console.log("Stopping SSE because an on-demand track is playing...");
      if (typeof window !== "undefined" && window.sseInstance) {
        window.sseInstance.close();
        window.sseInstance = null;
      }
    }
  
    // Reset elapsed time if the track has finished playing
    if (!isLiveStream && nowPlayingData?.elapsed >= nowPlayingData?.duration) {
      setNowPlayingData((prevData) => ({
        ...prevData,
        elapsed: 0, // Reset elapsed time for on-demand replay
      }));
    }
  
    navigator.vibrate(50); // Ensure this is executed as part of user interaction
    setIsPlaying(true);
  
    // Ensure the correct track source is set
    const audioElement = audioRef.current?.audio?.current;
    if (audioElement) {
      const source = isLiveStream ? streamUrl : nowPlayingData?.url;
  
      if (audioElement.src !== source) {
        console.log(`Updating audio source to: ${source}`);
        audioElement.pause(); // Pause before switching source
        audioElement.src = source;
        
        audioElement.oncanplay = () => {
          console.log('Source ready, starting playback...');
          audioElement
            .play()
            .then(() => console.log('Playback started.'))
            .catch((err) => console.error('Error playing audio:', err));
        };
  
        audioElement.onerror = () => {
          console.error(`Failed to load audio source: ${source}`);
        };
      } else {
        console.log('Playing current source...');
        audioElement
          .play()
          .then(() => console.log('Playback resumed.'))
          .catch((err) => console.error('Error playing audio:', err));
      }
    }
  };
  

  const handlePause = () => {
    console.log('Pause triggered'); // Debugging log
    navigator.vibrate(50); // Ensure this is executed as part of user interaction
    setIsPlaying(false);
    
  };

  const handleTrackEnd = () => {
    console.log("Track ended");
    if (!isLiveStream) {
      setNowPlayingData((prevData) => ({
        ...prevData,
        elapsed: 0,
      }));
      setIsPlaying(false);
  
      
    }
  };
  
  
  
  
  
  
  
  
  
  
  
  

  useEffect(() => {
    const audioElement = audioRef.current?.audio?.current;
    if (audioElement && !isLiveStream) {
      const intervalId = setInterval(() => {
        if (audioElement.duration && audioElement.duration !== Infinity) {
          setNowPlayingData((prevData) => ({
            ...prevData,
            duration: audioElement.duration,
          }));
          clearInterval(intervalId);
        }
      }, 500);

      return () => clearInterval(intervalId);
    }
  }, [streamUrl, isLiveStream, setNowPlayingData]);

  useEffect(() => {
    if ('mediaSession' in navigator) {
      // Always set play and pause handlers
      navigator.mediaSession.setActionHandler('play', handlePlay);
      navigator.mediaSession.setActionHandler('pause', handlePause);
  
      // Conditionally set seek handlers for on-demand media
      if (!isLiveStream) {
        navigator.mediaSession.setActionHandler('seekbackward', () => {
          const audio = audioRef.current?.audio?.current;
          if (audio) audio.currentTime = Math.max(audio.currentTime - 10, 0);
        });
  
        navigator.mediaSession.setActionHandler('seekforward', () => {
          const audio = audioRef.current?.audio?.current;
          if (audio) audio.currentTime = Math.min(audio.currentTime + 10, audio.duration);
        });
      } else {
        // Remove seek handlers for live streams
        navigator.mediaSession.setActionHandler('seekbackward', null);
        navigator.mediaSession.setActionHandler('seekforward', null);
      }
    }
  }, [isLiveStream, handlePlay, handlePause]);
  

  const handleListen = (currentTime) => {
    if (!isLiveStream && typeof currentTime === 'number') {
      setNowPlayingData((prevData) => ({
        ...prevData,
        elapsed: Math.min(currentTime, prevData.duration),
      }));
    }
  };

  return (
    <div
      {...swipeHandlers} // Attach swipe handlers to the player
      className="CustomAudioPlayerPosition"
      style={{
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#1d1d1d',
        color: '#fff',
        padding: '10px 0',
      }}
    >
      {/* Left Section: Album Art */}
      <div
        style={{
          width: '60px', // Fixed width for art section
          height: '60px', // Match height to width for a square
          flexShrink: 0, // Prevent resizing
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading ? (
          <Skeleton variant="rectangular" width={60} height={60} />
        ) : (
          <img
            src={nowPlayingData?.art || 'https://placehold.co/60'}
            alt="Album Art"
            style={{ width: '100%', height: '100%', borderRadius: '0px' }}
          />
        )}
      </div>
  
      {/* Center Section: Title and Artist */}
      <animated.div
        style={{
          flex: 1, // Center section takes remaining space
          padding: '0 10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          overflow: 'hidden', // Prevent text overflow
          transform: contentStyle.x.to((x) => `translateX(${x}%)`), // Horizontal animation
          opacity: contentStyle.opacity, // Fade animation
          minWidth: 0, // Allow text truncation in flexbox
        }}
      >
        {isLoading ? (
          <>
            <Skeleton variant="text" width="80%" height={20} />
            <Skeleton variant="text" width="60%" height={15} />
          </>
        ) : (
          <>
            <div
              style={{
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'nowrap',
                overflow: 'hidden', // Clip overflowing text
                textOverflow: 'ellipsis', // Add ellipsis
              }}
            >
              {nowPlayingData?.title || 'Sample Track Name'}
            </div>
            <div
              style={{
                fontSize: '12px',
                color: '#999',
                whiteSpace: 'nowrap',
                overflow: 'hidden', // Clip overflowing text
                textOverflow: 'ellipsis', // Add ellipsis
              }}
            >
              {nowPlayingData?.artist || 'Sample Artist'}
            </div>
          </>
        )}
      </animated.div>
  
      {/* Right Section: Play/Pause Button */}
      <div
        style={{
          width: '60px', // Fixed width for play/pause section
          height: '60px', // Match height to width for a square
          flexShrink: 0, // Prevent resizing
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading ? (
          <Skeleton variant="rectangular" width={40} height={40} />
        ) : (
          <AudioPlayer
            ref={audioRef}
            src={isLiveStream ? streamUrl : nowPlayingData?.url}
            showSkipControls={false}
            showJumpControls={false}
            showDownloadProgress={true}
            customAdditionalControls={[]}
            customProgressBarSection={[
              'CURRENT_TIME',
              <CustomProgressBar
                key="custom-bar"
                initialElapsed={initialElapsed}
                duration={duration}
                color={isLiveStream ? progressColor : '#868686'}
              />,
              'DURATION',
            ]}
            customIcons={{
              play: <PlayArrowIcon style={{ fontSize: 40 }} />,
              pause: <PauseIcon style={{ fontSize: 40 }} />,
            }}
            layout="horizontal"
            onEnded={!isLiveStream ? () => playNextTrack(nowPlayingData?.trackList || []) : undefined}
            onPlay={handlePlay}
            onPause={handlePause}
            onListen={handleListen}
          />
        )}
      </div>
    </div>
  );
  
  
};

export default AudioPlayerComponent;
