import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import LooksOneSharpIcon from "@mui/icons-material/LooksOneSharp";
import LooksTwoSharpIcon from "@mui/icons-material/LooksTwoSharp";
import FlashingLiveIndicator from "./FlashingLiveIndicator";

const LiveIndicator = () => {
  const [liveStatuses, setLiveStatuses] = useState({
    wssr: false,
    wssr_node_2: false,
  });

  useEffect(() => {
    const connectToSse = (station) => {
      const sseUri = `https://broadcast.wssr.stream/api/live/nowplaying/sse?cf_connect=${JSON.stringify({
        subs: { [`station:${station}`]: {} },
      })}`;

      console.log(`Connecting to SSE for station: ${station}`);

      const eventSource = new EventSource(sseUri);

      eventSource.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          console.log(`SSE Data for ${station}:`, data);

          // Ignore empty payloads
          if (!data || Object.keys(data).length === 0) {
            console.log(`Empty SSE payload for station: ${station}`);
            return;
          }

          const isLive = data?.pub?.data?.np?.live?.is_live || false;

          // Update state only if the live status changes
          setLiveStatuses((prevStatuses) => {
            const newStatuses = { ...prevStatuses, [station]: isLive };
            if (JSON.stringify(prevStatuses) !== JSON.stringify(newStatuses)) {
              return newStatuses;
            }
            return prevStatuses;
          });
        } catch (error) {
          console.error(`Error parsing SSE data for ${station}:`, error);
        }
      };

      eventSource.onerror = (error) => {
        console.error(`SSE Error for station ${station}:`, error);
        setLiveStatuses((prevStatuses) => ({ ...prevStatuses, [station]: false }));
        eventSource.close(); // Close connection on error
      };

      return eventSource;
    };

    // Connect to SSE for both stations
    const sseWssr = connectToSse("wssr");
    const sseWssrNode2 = connectToSse("wssr_node_2");

    // Cleanup on component unmount
    return () => {
      sseWssr.close();
      sseWssrNode2.close();
    };
  }, []);

  return (
    <div style={{ marginBottom: "1rem", display: "flex", alignItems: "center", gap: "1rem" }}>
      {liveStatuses.wssr && (
        <div key="wssr" style={{ display: "flex", alignItems: "center" }}>
          <Button
            size="small"
            color="primary"
            style={{ pointerEvents: "none" }}
            endIcon={<LooksOneSharpIcon />}
          >
            Deck
          </Button>
          <FlashingLiveIndicator station="wssr" />
        </div>
      )}

      {liveStatuses.wssr_node_2 && (
        <div key="wssr_node_2" style={{ display: "flex", alignItems: "center" }}>
          <Button
            size="small"
            color="secondary"
            style={{ pointerEvents: "none" }}
            endIcon={<LooksTwoSharpIcon />}
          >
            Deck
          </Button>
          <FlashingLiveIndicator station="wssr_node_2" />
        </div>
      )}
    </div>
  );
};

export default LiveIndicator;
