import React, { useEffect, useState, useContext, useRef } from 'react';
import { NowPlayingContext } from './NowPlayingProvider';

const CustomProgressBar = ({ initialElapsed, duration, color }) => {
  const { isPlaying, isLiveStream, setNowPlayingData } = useContext(NowPlayingContext);
  const [elapsed, setElapsed] = useState(initialElapsed || 0);
  const [progressPercentage, setProgressPercentage] = useState(
    ((initialElapsed || 0) / duration) * 100
  );
  const updateTimer = useRef(null); // For context updates
  const localTimer = useRef(null); // For smooth local updates

  useEffect(() => {
    // Reset when the track changes
    setElapsed(initialElapsed);
    setProgressPercentage(((initialElapsed || 0) / duration) * 100);
  }, [initialElapsed, duration]);

  const updateProgress = () => {
    setElapsed((prevElapsed) => {
        const newElapsed = Math.min(prevElapsed + 1, duration);

        setProgressPercentage((newElapsed / duration) * 100);

        // Conditionally update context state
        if (elapsed !== newElapsed) {
            setNowPlayingData((prevData) => ({
                ...prevData,
                elapsed: newElapsed,
            }));
        }

        return newElapsed;
    });
};

useEffect(() => {
  if (isLiveStream) {
      // Continuous progression for livestreams
      if (!updateTimer.current) {
          updateTimer.current = setInterval(updateProgress, 1000);
      }
  } else if (isPlaying) {
      // Progression for on-demand when playing
      if (!localTimer.current) {
          localTimer.current = setInterval(() => {
              setElapsed((prevElapsed) => {
                  const newElapsed = Math.min(prevElapsed + 1, duration);

                  // Stop at the end of the track
                  if (newElapsed >= duration) {
                      clearInterval(localTimer.current);
                      localTimer.current = null;
                  }

                  setProgressPercentage((newElapsed / duration) * 100);

                  return newElapsed;
              });
          }, 1000);
      }
  } else {
      // Stop progression when on-demand is paused
      clearInterval(localTimer.current);
      localTimer.current = null;
  }

  return () => {
      clearInterval(updateTimer.current);
      updateTimer.current = null;
      clearInterval(localTimer.current);
      localTimer.current = null;
  };
}, [isPlaying, isLiveStream, duration]);

  const handleSeek = (event) => {
    if (isLiveStream) return; // Disallow seeking for live streams

    const rect = event.target.getBoundingClientRect();
    const clickX = event.clientX - rect.left;
    const newElapsed = Math.min((clickX / rect.width) * duration, duration);

    setElapsed(newElapsed);
    setProgressPercentage((newElapsed / duration) * 100);

    // Immediate context update for seeking
    setNowPlayingData((prevData) => ({
      ...prevData,
      elapsed: newElapsed,
    }));
  };

  return (
    <div
      className="rhap_progress-container"
      style={{
        position: 'fixed',
        bottom: '60px',
        left: '0',
        right: '0',
        margin: '0',
        height: '2px',
        backgroundColor: 'rgb(29 29 29 / 0%)',
        cursor: isLiveStream ? 'default' : 'pointer',
      }}
      onClick={handleSeek}
    >
      <div
        className="rhap_progress-filled"
        style={{
          width: `${progressPercentage}%`,
          height: '100%',
          backgroundColor: color,
          transition: 'width 0.2s ease', // Smooth transition
          borderRadius: '0px',
        }}
      />
    </div>
  );
};

export default CustomProgressBar;
