import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const scrollPositions = {}; // Store scroll positions by path

const useScrollRestoration = () => {
  const location = useLocation();
  
  useEffect(() => {
    const currentPath = location.pathname;

    // Save the scroll position whenever we navigate away from the current page
    const handleBeforeUnload = () => {
      scrollPositions[currentPath] = window.scrollY;
    };

    // Attach the listener to store scroll position when the user clicks a navigation link
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Restore the scroll position if it exists
    if (scrollPositions[currentPath]) {
      window.scrollTo(0, scrollPositions[currentPath]);
    } else {
      window.scrollTo(0, 0); // Default scroll to top
    }

    // Cleanup the event listener when component unmounts or path changes
    return () => {
      // Save scroll position before unmounting or navigating away
      scrollPositions[currentPath] = window.scrollY;
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [location]);

  return null;
};

export default useScrollRestoration;
