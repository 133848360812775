import React from 'react';
import Button from '@mui/material/Button';
import LooksOneSharpIcon from '@mui/icons-material/LooksOneSharp';
import LooksTwoSharpIcon from '@mui/icons-material/LooksTwoSharp';



function StationStatus() {

  return (
    <div>
        <div>
            <Button
                size="small"
                color="primary"
                style={{
                pointerEvents: 'none',
                }}
                endIcon={<LooksOneSharpIcon />}
            >
                Deck
            </Button> Online
           
        </div>
    <div>
            <Button
                size="small"
                color="secondary"
                style={{
                pointerEvents: 'none',
                }}
                endIcon={<LooksTwoSharpIcon />}
            >
                Deck
            </Button> Online
        </div>
    </div>
  );
}

export default StationStatus;
