import React, { useRef, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Box, IconButton, ImageListItem, Typography, Avatar, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import PlayArrowSharpIcon from '@mui/icons-material/PlayArrowSharp';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import LinearProgress from '@mui/material/LinearProgress';
import { NowPlayingContext } from '../components/NowPlayingProvider';


const token = process.env.REACT_APP_WSSR_API_TOKEN;

const NewTracksCarousel = () => {
    const [tracks, setTracks] = useState([]);
    const [loading, setLoading] = useState(true);
    const scrollRef = useRef(null);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const [showLeftNav, setShowLeftNav] = useState(false);
    const [showRightNav, setShowRightNav] = useState(true);

    const {
        playSpecificTrack,
        currentTrackId,
        isPlaying,
        setIsPlaying,
        loadingTrackId,
        setLoadingTrackId
    } = useContext(NowPlayingContext);
    
    const handlePlay = async (track, index) => {
        console.log(`Attempting to play track: ${track.path}`);
    
        if (isPlaying && currentTrackId === track.path) {
            setIsPlaying(false);
            return;
        }
    
        if (currentTrackId === track.path && !isPlaying) {
            setIsPlaying(true);
            return;
        }
    
        const mediaUrl = track.links?.download
            ? `https://broadcast.wssr.stream${track.links.download}`
            : null;
    
        if (mediaUrl) {
            try {
                setLoadingTrackId(track.path);  // 🔹 Confirm this is firing
                console.log(`Set loadingTrackId: ${track.path}`);  // 🔎 Log loading state
    
                const response = await axios.get(mediaUrl, {
                    headers: { Authorization: token },
                    responseType: 'blob',
                });
    
                const blobUrl = URL.createObjectURL(response.data);
    
                playSpecificTrack(blobUrl, {
                    title: track.media?.title || 'Unknown Title',
                    artist: track.media?.artist || 'Unknown Artist',
                    art: track.media?.art 
                        ? `https://broadcast.wssr.stream${track.media.art}` 
                        : null,
                    duration: track.media?.length || 0,
                    trackList: tracks,
                }, track.path, index);
    
                track.url = blobUrl;  // Cache BLOB URL
                setIsPlaying(true);
                setLoadingTrackId(null); // 🔹 Clear loading state after track loads
            } catch (error) {
                console.error('Error fetching media file:', error);
                setLoadingTrackId(null); // 🔹 Clear loading state on error
            }
        } else {
            console.error('Invalid media URL for track:', track);
        }
    };
    
    

    // Fetch Data from API
    useEffect(() => {
        const fetchTracks = async () => {
            try {
                const response = await axios.get(
                    'https://broadcast.wssr.stream/api/station/1/files/list?sort=timestamp&sortOrder=DESC&currentDirectory=New',
                    { headers: { Authorization: token } }
                );

                const filteredTracks = response.data.filter(track => track.path_short !== 'cover.png');

                const tracksWithFullArtUrl = filteredTracks.map((track) => ({
                    ...track,
                    fullArtUrl: track.media?.art
                        ? `https://broadcast.wssr.stream${track.media.art}`
                        : null,
                }));

                setTracks(tracksWithFullArtUrl);
            } catch (error) {
                console.error('Error fetching tracks:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchTracks();
    }, []);

    // Scroll Logic for Navigation
    const checkScrollPosition = () => {
            if (!scrollRef.current) return;
    
            const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
    
            setShowLeftNav(Math.round(scrollLeft) > 35);
            setShowRightNav(Math.round(scrollLeft) < scrollWidth - clientWidth);
        };
    
        useEffect(() => {
            const scrollElement = scrollRef.current;
            if (!scrollElement) return;
        
            // Ensures `scrollLeft` is checked AFTER layout calculations
            requestAnimationFrame(() => {
                checkScrollPosition();
            });
        
            scrollElement.addEventListener('scroll', checkScrollPosition);
        
            return () => scrollElement.removeEventListener('scroll', checkScrollPosition);
        }, [scrollRef, tracks]); // ✅ Added `tracks` to recheck after data loads
        
    
        const handleNextScroll = () => {
            if (scrollRef.current) {
                scrollRef.current.scrollBy({ left: 300, behavior: 'smooth' });
            }
        };
    
        const handlePrevScroll = () => {
            if (scrollRef.current) {
                scrollRef.current.scrollBy({ left: -300, behavior: 'smooth' });
            }
        };

    return (
        <Box sx={{ pb: 4 }}>
            <Box
                sx={{
                    backgroundColor: 'rgba(30, 30, 30, 0.0)',
                    borderRadius: '4px',
                    p: 2,
                }}
            >
                {loading ? (
                    <Box display="flex" justifyContent="center" py={4}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            position: 'relative',
                            overflow: 'hidden',
                            width: '100%',
                        }}
                    >
                        {/* Left Navigation */}
                        {isDesktop && (
                            <IconButton
                                sx={{
                                    position: 'absolute',
                                    left: 0,
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    color: '#fff',
                                    borderRadius: '0%',
                                    zIndex: 3, // Ensures visibility above content
                                    opacity: showLeftNav ? 1 : 0,
                                    visibility: showLeftNav ? 'visible' : 'hidden',
                                    transition: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
                                }}
                                onClick={handlePrevScroll}
                            >
                                <ArrowBackIosNewIcon />
                            </IconButton>
                        )}

                        <Box
                            ref={scrollRef}
                            sx={{
                                display: 'flex',
                                overflowX: 'scroll',
                                scrollSnapType: 'x mandatory',
                                position: 'relative',
                                px: isDesktop ? 4 : 0,
                                '&::-webkit-scrollbar': { display: 'none' },
                            }}
                        >
                            {tracks.map((track, index) => (
                                <ImageListItem
                                key={index}
                                sx={{
                                    flex: '0 0 auto',
                                    width: { xs: '150px', md: '200px' },
                                    height: { xs: '225px', md: '300px' },
                                    scrollSnapAlign: 'start',
                                    marginRight: '12px',
                                    borderRadius: 0,
                                    overflow: 'hidden',
                                    position: 'relative',
                                    boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.2)',
                                    backgroundImage: `url(${track.fullArtUrl ? encodeURI(track.fullArtUrl) : 'https://via.placeholder.com/200x300?text=No+Image'})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    // 👇 Force Height Override
                                    '&.MuiImageListItem-root': {
                                        height: { xs: '225px !important', md: '300px !important' },
                                    }
                                }}
                                onClick={() => handlePlay(track, index)}
                            >

                                {/* Loading Progress Bar */}
                                {loadingTrackId === track.path && (
                                    <LinearProgress
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            zIndex: 999,
                                        }}
                                    />
                                )}

                                {/* Gradient Overlay */}
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8))',
                                    }}
                                />
                            
                                {/* "New Track" Tag */}
                                <Typography
                                    sx={{
                                        position: 'absolute',
                                        top: 5,
                                        left: 5,
                                        color: '#fff',
                                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                        px: 1,
                                        py: 0.5,
                                        fontSize: '12px',
                                        borderRadius: '0%',
                                    }}
                                >
                                    New Track
                                </Typography>
                            
                                {/* Play/Equalizer Icon */}
                                {isPlaying && currentTrackId === track.path ? (
                                    <EqualizerIcon
                                        fontSize="large"
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            color: 'white',
                                            opacity: 0.8,
                                            cursor: 'auto',
                                        }}
                                    />
                                ) : (
                                    <PlayArrowSharpIcon
                                        fontSize="large"
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            color: 'white',
                                            opacity: 0.8,
                                            cursor: 'pointer',
                                        }}
                                    />
                                )}
                            
                                {/* Album Art & Artist Name */}
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        bottom: 5,
                                        left: 5,
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                    }}
                                >
                                    <Avatar
                                        src={track.fullArtUrl}
                                        sx={{ width: 30, height: 30 }}
                                    />
                                    <Typography
                                        sx={{
                                            color: '#fff',
                                            fontSize: '12px',
                                            maxWidth: '100px',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        {track.media?.artist || 'Unknown Artist'}
                                    </Typography>
                                </Box>
                            </ImageListItem>
                            
                            
                            ))}
                        </Box>

                        {/* Right Navigation */}
                        {isDesktop && (
                            <IconButton
                                sx={{
                                    position: 'absolute',
                                    right: 0,
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    color: '#fff',
                                    borderRadius: '0%',
                                    zIndex: 3,
                                    opacity: showRightNav ? 1 : 0,
                                    visibility: showRightNav ? 'visible' : 'hidden',
                                    transition: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
                                }}
                                onClick={handleNextScroll}
                            >
                                <ArrowForwardIosIcon />
                            </IconButton>
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default NewTracksCarousel;
