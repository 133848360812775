import React, { useContext } from "react";
import { Typography, Box } from "@mui/material";
import SensorsIcon from "@mui/icons-material/Sensors";
import PlayArrowSharpIcon from "@mui/icons-material/PlayArrowSharp";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import { useTheme } from "@mui/material/styles";
import { NowPlayingContext } from "./NowPlayingProvider";

const BroadcastIndicator = ({ station }) => {
  const theme = useTheme();
  const {
    isPlaying,
    setIsPlaying,
    isLiveStream,
    setIsLiveStream,
    streamUrl,
    setStreamUrl,
    setNowPlayingData,
    toggleStreamUrl,
    setLiveStreamNowPlayingData,
    setCurrentTrackId,
  } = useContext(NowPlayingContext);

  // Determine colors based on station
  const borderColor = station === "wssr" ? theme.palette.primary.main : theme.palette.secondary.main;

  // Handle play toggle
  const handlePlayToggle = () => {
    if (isPlaying && isLiveStream) return; // Prevent further clicks during playback

    console.log(`BroadcastIndicator clicked for station: ${station}`);
    setIsPlaying(false); // Stop any playing track
    setCurrentTrackId(null); // Clear the currently playing track ID
  
    console.log(`Switching to live stream for station: ${station}`);
    navigator.vibrate(50); // Vibrate on switch toggle
  
    // Stop any on-demand playback and switch to the live stream
    setIsLiveStream(true); // Ensure the live stream is active
    setStreamUrl(
      station === "wssr"
        ? "https://broadcast.wssr.stream/listen/wssr/radio.mp3"
        : "https://broadcast.wssr.stream/listen/wssr_node_2/radio.mp3"
    ); // Update stream URL for the selected station
    setLiveStreamNowPlayingData(); // Set live stream NowPlayingData
    setIsPlaying(true); // Start playback
  };

  return (
    <Box
      onClick={isPlaying && isLiveStream ? null : handlePlayToggle} // Disable onClick if playback has started
      sx={{
        border: `1px solid ${borderColor}`, // Border with dynamic color
        padding: "2px 4px", // 2px top/bottom, 4px left/right
        display: "inline-flex", // Flexbox for alignment
        alignItems: "center",
        borderRadius: "0", // Match no border radius
        cursor: isPlaying && isLiveStream ? "default" : "pointer", // Default cursor during playback
      }}
    >
      {/* Sensor Icon */}
      <SensorsIcon
        sx={{
          color: borderColor,
          fontSize: "0.75rem", // Match size of flashing square in FlashingLiveIndicator
          marginRight: 0.5, // Match spacing
        }}
      />
      {/* Broadcasting Text */}
      <Typography
        variant="body2"
        sx={{
          color: borderColor,
          fontSize: "0.65rem", // Match text size from FlashingLiveIndicator
        }}
      >
        Broadcasting Now
      </Typography>
      {/* Play/Equalizer Icon */}
      <Box
        sx={{
          marginLeft: "4px", // Spacing to the right of the text
        }}
      >
        {isPlaying && isLiveStream ? (
          <EqualizerIcon
            sx={{
              color: borderColor,
              fontSize: "0.75rem", // Match SensorsIcon size
            }}
          />
        ) : (
          <PlayArrowSharpIcon
            sx={{
              color: borderColor,
              fontSize: "0.75rem", // Match SensorsIcon size
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default BroadcastIndicator;
