// components/SnackbarContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import moment from 'moment';

const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [snackbarQueue, setSnackbarQueue] = useState([]); // Queue to hold messages
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState('info');
  const [alertColor, setAlertColor] = useState('primary'); // State to control Alert color
  const [combinedScheduleData, setCombinedScheduleData] = useState([]);

  const showSnackbar = (message, severity = 'info', color = 'primary') => {
    // Add new message to the queue
    setSnackbarQueue((prevQueue) => [...prevQueue, { message, severity, color }]);
  };

  const processQueue = () => {
    if (snackbarQueue.length > 0) {
      const { message, severity, color } = snackbarQueue[0];
      setSnackbarMessage(message);
      setSeverity(severity);
      setAlertColor(color);
      setSnackbarOpen(true);

      // Remove the displayed message from the queue
      setSnackbarQueue((prevQueue) => prevQueue.slice(1));
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (!snackbarOpen && snackbarQueue.length > 0) {
      processQueue(); // Process the next item in the queue when the Snackbar is closed
    }
  }, [snackbarQueue, snackbarOpen]);

  useEffect(() => {
    // Fetch schedule data for event monitoring
    const stationIds = ['wssr', 'wssr_node_2'];
    const requests = stationIds.map((stationId) =>
      axios.get(`https://broadcast.wssr.stream/api/station/${stationId}/schedule`)
    );

    Promise.all(requests).then((responses) => {
      const scheduleData = responses
        .map((response, index) => ({
          data: response.data,
          stationId: stationIds[index],
        }))
        .flat();

      const combinedData = scheduleData.flatMap((station) =>
        station.data.map((event) => ({
          ...event,
          stationId: station.stationId,
        }))
      );

      combinedData.sort((a, b) => new Date(a.start) - new Date(b.start));
      setCombinedScheduleData(combinedData);
    });
  }, []);

  useEffect(() => {
    // Monitor for new events starting
    const intervalId = setInterval(() => {
      const now = moment();
      const newEvents = combinedScheduleData.filter(
        (event) => moment(event.start).isSame(now, 'minute') && !event.notified
      );

      newEvents.forEach((newEvent) => {
        const message =
          newEvent.stationId === 'wssr'
            ? `"${newEvent.name}" has started on Deck 1`
            : `"${newEvent.name}" has started on Deck 2`;

        const color = newEvent.stationId === 'wssr' ? 'primary' : 'secondary';

        showSnackbar(message, 'info', color);
        newEvent.notified = true; // Mark this event as notified
      });
    }, 60000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [combinedScheduleData]);

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        onExited={processQueue} // Trigger next message in queue when current Snackbar exits
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={severity}
          sx={{ width: '100%' }}
          color={alertColor} // Apply dynamic color based on station ID
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => useContext(SnackbarContext);
