import React, { useState, useEffect, useRef } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Skeleton,
  Box,
  IconButton,
  Collapse,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';

const ShowTrackListHeader = ({ id }) => {
  const [showDetails, setShowDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const descriptionRef = useRef(null);

  useEffect(() => {
    const fetchShowDetails = async () => {
      try {
        const response = await axios.get(
          `https://broadcast.wssr.stream/api/station/1/public/podcast/${id}`
        );
        setShowDetails(response.data);
      } catch (error) {
        console.error('Error fetching show details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchShowDetails();
  }, [id]);

  useEffect(() => {
    if (!loading && descriptionRef.current) {
      const { scrollHeight, clientHeight } = descriptionRef.current;
      setIsTruncated(scrollHeight > clientHeight);
    }
  }, [loading, showDetails?.description]);

  const handleExpandClick = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <Card
      sx={{
        margin: '25px 0',
        position: 'relative',
        height: expanded ? 'auto' : 300,
        overflow: 'hidden',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      }}
    >
      {loading ? (
        <Skeleton variant="rectangular" height="100%" />
      ) : (
        <Box
          sx={{
            height: '300px',
            width: '100%',
            position: 'absolute',
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(${showDetails?.art})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      )}
      <CardContent
        sx={{
          position: 'relative',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: expanded ? 'flex-start' : 'center',
          color: 'white',
          textShadow: '0px 0px 5px rgba(0, 0, 0, 0.8)',
          bgcolor: expanded ? 'background.paper' : 'transparent',
          transition: 'background-color 0.3s ease',
        }}
      >
        {loading ? (
          <>
            <Skeleton variant="text" width="60%" height={40} sx={{ bgcolor: 'grey.800' }} />
            <Skeleton variant="text" width="90%" height={20} sx={{ bgcolor: 'grey.800' }} />
          </>
        ) : (
          <>
            <Typography
              variant="h4"
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                mb: 2,
              }}
            >
              {showDetails?.title || 'Unknown Title'}
            </Typography>
            <Typography
              ref={descriptionRef}
              variant="body1"
              sx={{
                display: expanded ? 'block' : '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: expanded ? 'none' : 3,
                overflow: 'hidden',
                transition: 'color 0.3s ease',
                mb: expanded ? 3 : 0, // Adds spacing at the bottom when expanded
              }}
            >
              {showDetails?.description || 'No description available.'}
            </Typography>
          </>
        )}
      </CardContent>

      {!loading && isTruncated && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            textAlign: 'center',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: 50,
              background: 'linear-gradient(to top, background.paper, transparent)',
            }}
          />
          <IconButton
            onClick={handleExpandClick}
            sx={{
              color: 'white',
              transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s',
            }}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Box>
      )}

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box
          sx={{
            bgcolor: 'background.paper',
            p: 2,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: 'text.primary',
            }}
          >
            {showDetails?.description || 'No description available.'}
          </Typography>
          {/* Adds bottom padding to prevent overlap with the icon */}
          <Box sx={{ height: 50 }} />
        </Box>
      </Collapse>
    </Card>
  );
};

export default ShowTrackListHeader;
