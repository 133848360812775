import React, { useState, useEffect, useContext } from "react";
import { NowPlayingContext } from "./NowPlayingProvider";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { IconButton } from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";
import LooksOneSharpIcon from "@mui/icons-material/LooksOneSharp";
import LooksTwoSharpIcon from "@mui/icons-material/LooksTwoSharp";
import { PiSoundcloudLogoDuotone } from "react-icons/pi";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const SongHistory = () => {
  const { station } = useContext(NowPlayingContext);

  const [songHistory, setSongHistory] = useState(() => {
    const savedHistory = localStorage.getItem("songHistoryData");
    return savedHistory ? JSON.parse(savedHistory) : [];
  });

  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");

  useEffect(() => {
    setLoading(true);
    const savedHistory = localStorage.getItem("songHistoryData");
    const parsedHistory = savedHistory ? JSON.parse(savedHistory) : null;

    if (parsedHistory && JSON.stringify(parsedHistory) === JSON.stringify(songHistory)) {
      // Keep `loading` as true until SSE confirms
    }
  }, [station]);

  useEffect(() => {
    const sseUri =
      `https://broadcast.wssr.stream/api/live/nowplaying/sse?cf_connect=` +
      JSON.stringify({ subs: { [`station:${station}`]: {} } });
    const sse = new EventSource(sseUri);

    sse.onmessage = (e) => {
      const data = JSON.parse(e.data);
      const newHistory = data?.pub?.data?.np?.song_history || [];

      if (newHistory.length > 0 && JSON.stringify(newHistory) !== JSON.stringify(songHistory)) {
        setSongHistory(newHistory);
        localStorage.setItem("songHistoryData", JSON.stringify(newHistory));
        setLoading(false); // Set loading to false after update
      } else if (newHistory.length > 0) {
        setLoading(false);
      }
    };

    sse.onerror = () => setLoading(false);

    return () => sse.close();
  }, [station, songHistory]);

  const handleOpen = (image) => {
    setOpen(true);
    setModalImage(image);
  };

  const handleClose = () => setOpen(false);

  const buttonColor = station === "wssr" ? "primary" : "secondary";

  const deckButton = loading ? (
    <Box display="flex" alignItems="center" sx={{ cursor: "none" }}>
      <CircularProgress size={20} color={buttonColor} sx={{ marginLeft: 1 }} />
    </Box>
  ) : (
    <Button size="small" color={buttonColor} endIcon={station === "wssr" ? <LooksOneSharpIcon /> : <LooksTwoSharpIcon />}>
      Deck
    </Button>
  );

  return (
    <div>
      <Box display="flex" alignItems="center">
        <h1>Just Missed</h1>
        {deckButton}
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3} sx={{ marginBottom: "8rem" }}>
          {songHistory.map((entry, index) => {
            const { song } = entry;
            const playedAt = moment.unix(entry.played_at).fromNow();

            return (
              <Grid item xs={12} key={entry.sh_id || index}>
                <Grid container spacing={2}>
                  <Grid item xs={5} md={4}>
                    <Box sx={{ width: "100%", paddingTop: "100%", position: "relative" }}>
                      <img
                        src={song.art}
                        style={{ width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}
                        alt={song.title || "Song Art"}
                        onClick={() => handleOpen(song.art)}
                      />

{song?.custom_fields?.elink && (
                          <IconButton
                              component="a"
                              href={song?.custom_fields?.elink}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(event) => event.stopPropagation()} // Prevent playback on click
                              sx={{
                                clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
                                  position: 'absolute',
                                  bottom: 2,
                                  right: 2,
                                  padding: '8px',
                                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                  color: '#FF991C',
                                  borderRadius: '0%',
                                  transition: 'all 0.2s ease-in-out',
                                  '&:hover': {
                                      backgroundColor: 'rgba(0, 0, 0, 0.9)',
                                      padding: '12px',
                                      clipPath: 'polygon(0 20%, 80% 0, 100% 80%, 20% 100%)',
                                  }
                              }}
                          >
                              <PiSoundcloudLogoDuotone />
                          </IconButton>
                      )}

                    </Box>
                  </Grid>
                  <Grid item xs={7} md={8} display="flex" justifyContent="center" flexDirection="column">
                    <Typography variant="subtitle1">{song.title || "Unknown Title"}</Typography>
                    <Typography variant="subtitle1" sx={{ color: "#999" }}>
                      {song.text === " - Live Broadcast"
                        ? entry.streamer
                        : song.artist || ""}
                    </Typography>

                    <Typography variant="subtitle2" sx={{ color: "#999" }}>{playedAt}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box> 

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <img src={modalImage} alt="Expanded Art" style={{ maxWidth: "90vw", maxHeight: "90vh" }} />
        </Box>
      </Modal>
    </div>
  );
};

export default SongHistory;
