// StationSwitch.js
import React, { useContext, useState, useEffect } from 'react';
import Switch from '@mui/material/Switch';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { NowPlayingContext } from './NowPlayingProvider';

export default function StationSwitch() {
  const { station, toggleStreamUrl, isLoading } = useContext(NowPlayingContext);
  const theme = useTheme();
  const [isSwitchDisabled, setIsSwitchDisabled] = useState(false);

  const checked = station === "wssr_node_2";

  // Disable switch during loading
  useEffect(() => {
    setIsSwitchDisabled(isLoading);
  }, [isLoading]);

  const handleToggle = () => {
    if (!isSwitchDisabled) {
      toggleStreamUrl();
      navigator.vibrate(50); // Vibrate on switch toggle
      setIsSwitchDisabled(true); // Disable switch immediately on toggle
  
      // Re-enable switch after a short delay to prevent rapid toggles
      setTimeout(() => setIsSwitchDisabled(false), 500);
    }
  };
  

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto', position: 'relative' }}>
      <Box
        component="svg"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 0,
        }}
      >
        <rect
          className="line"
          x="0"
          y="0"
          width="100%"
          height="100%"
          rx="0"
          ry="0"
          stroke={checked ? theme.palette.secondary.main : theme.palette.primary.main}
          strokeWidth="3px"
          fill="transparent"
          style={{ transition: 'stroke 0.3s ease' }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          paddingTop: 0,
          paddingLeft: '5px',
          paddingRight: 0,
          paddingBottom: '7px',
          height: 35,
          position: 'relative',
          zIndex: 1,
          backgroundColor: 'rgba(56, 56, 56, 0.3)',
        }}
      >
        <Box
          sx={{
            width: 35,
            height: 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '7px', // Added paddingTop for both spinner and text
          }}
        >
          {isLoading ? (
            <CircularProgress
              size={16}
              sx={{
                color: checked ? theme.palette.secondary.main : theme.palette.primary.main,
              }}
            />
          ) : (
            <Typography sx={{ fontSize: '0.75rem' }}>DECK</Typography>
          )}
        </Box>

        <Switch
          checked={checked}
          onChange={handleToggle}
          disabled={isSwitchDisabled}
          sx={{
            '& .MuiSwitch-thumb': {
              borderRadius: '0px',
              width: 20,
              height: 20,
              backgroundColor: checked ? theme.palette.secondary.main : theme.palette.primary.main,
              transition: 'background-color 0.3s ease',
              top: '25%',
              transform: 'translateY(15%)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '12px',
              color: '#fff',
              position: 'relative',
              '&::before': {
                content: checked ? '"2"' : '"1"',
                position: 'absolute',
              },
            },
            '& .MuiSwitch-track': {
              borderRadius: '0px',
              opacity: 1,
              backgroundColor: '#272727',
              height: 20,
            },
            '& .Mui-checked + .MuiSwitch-track': {
              backgroundColor: '#272727',
              opacity: 1,
            },
          }}
        />
      </Box>
    </Box>
  );
}
