import React, { useState, useEffect } from 'react';
import { Box, Grid, Card, CardContent, CardMedia, Typography, CardActionArea, Skeleton, Fade } from '@mui/material';
import { Link } from 'react-router-dom'; // Import Link for navigation
import axios from 'axios';

// Component to render each show card with skeleton, fade-in, and linking to detail page
function ShowCard({ id, title, description, image }) {
  const [imageLoaded, setImageLoaded] = useState(false); // Track when the image is loaded

  return (
    <Card>
      <CardActionArea>
        {/* Wrap the card content inside a Link to navigate to show detail using the show id */}
        <Link to={`/show/${id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
          {/* CardMedia with skeleton and fade-in */}
          {!imageLoaded && <Skeleton variant="rectangular" height={300} />} {/* Show skeleton for the image */}
          <Fade in={imageLoaded} timeout={600}>
            <CardMedia
              component="img"
              height="300"
              image={image}  // Use the `art` field as the image URL
              alt={title}
              onLoad={() => setImageLoaded(true)}  // Set image as loaded once ready
              onError={(e) => {
                e.target.onerror = null;
                console.log(`Image failed to load for ${title}`);
              }}
              style={{ display: imageLoaded ? 'block' : 'none' }} // Hide image while loading
            />
          </Fade>

          <CardContent>
            {/* Title with skeleton */}
            {title ? (
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {title}
              </Typography>
            ) : (
              <Skeleton variant="text" width="80%" /> // Show skeleton for title
            )}

            {/* Description with skeleton */}
            {description ? (
              <Typography
                variant="body2"
                sx={{
                  color: 'text.secondary',
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 3,  // Limits the text to 3 lines
                  textOverflow: 'ellipsis',
                }}
              >
                {description || 'No description available.'}
              </Typography>
            ) : (
              <Skeleton variant="text" width="100%" /> // Show skeleton for description
            )}
          </CardContent>
        </Link>
      </CardActionArea>
    </Card>
  );
}

// Main component to fetch API data and render multiple cards with layout
export default function ShowList() {
  const [shows, setShows] = useState([]); // State to hold API data
  const [loading, setLoading] = useState(true); // State to track if data is still loading

  useEffect(() => {
    const fetchShows = async () => {
      try {
        // Use the public API endpoint
        const response = await axios.get(`https://broadcast.wssr.stream/api/station/1/public/podcasts`);
        setShows(response.data); // Set the fetched data to state
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false); // Stop loading on error
      }
    };

    fetchShows();
  }, []);

  return (
    <Box sx={{ flexGrow: 1, p: 2, paddingBottom: '130px' }}> {/* Add paddingBottom to avoid overlap with navigation */}
      <Grid container spacing={2}>
        {loading
          ? Array.from(new Array(6)).map((_, index) => (
              <Grid item key={index} xs={12} sm={12} md={index % 5 < 2 ? 6 : 4}>
                {/* Skeleton Card */}
                <Card>
                  <Skeleton variant="rectangular" height={300} />
                  <CardContent>
                    <Skeleton variant="text" width="80%" />
                    <Skeleton variant="text" width="100%" />
                  </CardContent>
                </Card>
              </Grid>
            ))
          : shows.map((show, index) => {
              const { id, title, description_short, art } = show; // Extract id, title, description, and art

              return (
                <Grid
                  item
                  key={id}
                  xs={12}
                  sm={12}
                  md={index % 5 < 2 ? 6 : 4} // First 2 items take 6 columns, next 3 take 4 columns
                >
                  {/* Pass the ID to the ShowCard component for linking */}
                  <ShowCard id={id} title={title} description={description_short} image={art} />
                </Grid>
              );
            })}
      </Grid>
    </Box>
  );
}
