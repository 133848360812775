import React, { createContext, useState } from 'react';

// Create the FeedContext
export const FeedContext = createContext();

// FeedProvider component to wrap your app
export const FeedProvider = ({ children }) => {
  const [feed, setFeed] = useState([]); // Store the feed data
  const [imageUrls, setImageUrls] = useState({}); // Store the image URLs for each feed item
  const [trackCounts, setTrackCounts] = useState({}); // Store the number of tracks for each directory
  const [totalDurations, setTotalDurations] = useState({}); // Store the total duration for each directory
  const [loading, setLoading] = useState(true); // Loading state for the initial fetch
  const [page, setPage] = useState(1); // Pagination
  const [hasMore, setHasMore] = useState(true); // Whether there are more items to load

  return (
    <FeedContext.Provider
      value={{
        feed,
        setFeed,
        imageUrls,
        setImageUrls,
        trackCounts,
        setTrackCounts,
        totalDurations,
        setTotalDurations,
        loading,
        setLoading,
        page,
        setPage,
        hasMore,
        setHasMore,
      }}
    >
      {children}
    </FeedContext.Provider>
  );
};
