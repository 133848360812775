import Box from "@mui/material/Box";

const Bullet = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.75)', color: '#f60', verticalAlign: "middle", position: "relative", top:'-2px' }}
    >
      ■
    </Box>
  );

  export default Bullet;