import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Skeleton from '@mui/material/Skeleton';
import LooksOneSharpIcon from '@mui/icons-material/LooksOneSharp';
import LooksTwoSharpIcon from '@mui/icons-material/LooksTwoSharp';
import FlashingLiveIndicator from '../components/FlashingLiveIndicator';
import LiveIndicator from '../components/LiveIndicator'; // Added LiveIndicator import

function ScheduleCard() {
  const [scheduleData, setScheduleData] = useState([]);
  const [combinedScheduleData, setCombinedScheduleData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLiveWssr, setIsLiveWssr] = useState(false);
  const [isLiveWssrNode2, setIsLiveWssrNode2] = useState(false);

  useEffect(() => {
    const stationIds = [`wssr`, `wssr_node_2`];

    const requests = stationIds.map((stationId) => {
      return axios.get(`https://broadcast.wssr.stream/api/station/${stationId}/schedule`);
    });

    Promise.all(requests).then((responses) => {
      const scheduleData = responses.map((response, index) => ({
        data: response.data,
        stationId: stationIds[index],
      })).flat();
      setScheduleData(scheduleData);

      const combinedData = scheduleData.flatMap((station) => {
        return station.data.map((event) => ({
          ...event,
          stationId: station.stationId,
        }));
      });

      const isLiveForStation = (stationId) => {
        const now = new Date();
        return combinedData.some((event) => {
          const eventStartTime = new Date(event.start);
          const eventEndTime = new Date(event.end || eventStartTime.getTime() + event.duration * 1000);
          return event.stationId === stationId && now >= eventStartTime && now <= eventEndTime;
        });
      };

      setIsLiveWssr(isLiveForStation('wssr'));
      setIsLiveWssrNode2(isLiveForStation('wssr_node_2'));

      combinedData.sort((a, b) => {
        return new Date(a.start) - new Date(b.start);
      });

      setCombinedScheduleData(combinedData);
      setLoading(false);
    });
  }, []);

  return (
    <div style={{ marginBottom: '8rem' }}>
      {/* Live Indicators */}
      <div style={{ marginBottom: '1rem' }}>
  <LiveIndicator />
</div>



      {scheduleData.length === 0 && loading && (
        Array.from(new Array(3)).map((_, index) => (
          <Card className='blur border-left' key={index} square sx={{ minWidth: 275, marginTop: '1rem' }}>
            <CardContent>
              <Skeleton variant="text" width="60%" />
              <Skeleton variant="text" width="40%" />
              <Skeleton variant="text" width="80%" />
              <Skeleton variant="text" width="50%" />
            </CardContent>
            <CardActions>
              <Skeleton variant="rectangular" width={100} height={40} />
            </CardActions>
          </Card>
        ))
      )}

      {!loading && combinedScheduleData.map((event, index) => {
        const eventStartTime = moment(event.start);
        const now = moment();

        // Check if the event has started
        const hasStarted = now.isAfter(eventStartTime);
        const formattedTime = hasStarted ? `Started ${eventStartTime.fromNow()}` : eventStartTime.fromNow();

        // Determine the appropriate card class based on event conditions
        const cardClass = `blur ${
          event.stationId === 'wssr_node_2' 
            ? event.is_now 
              ? 'border-left-flash-node2' 
              : 'border-left-node2' 
            : event.is_now 
              ? 'border-left-flash' 
              : 'border-left'
        }`;

        return (
          <Card
            className={cardClass}
            key={index}
            square
            sx={{ minWidth: 275, marginTop: '1rem' }}
          >
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {eventStartTime.format('MMMM D, YYYY')}
              </Typography>
              <Typography variant="h5" component="div">
                {event.name}
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {eventStartTime.format('h:mm A [ET]')}
              </Typography>
              <Typography variant="body2" className="uppercase">
                <PlayArrowIcon sx={{ color: "#999", fontSize: "1rem", verticalAlign: "middle", position: "relative", top: "-.1rem" }} /> 
                {formattedTime}
              </Typography>
            </CardContent>
            <CardActions>
              {event.stationId === 'wssr_node_2' ? (
                <Button
                  size="small"
                  color="secondary"
                  style={{
                    pointerEvents: 'none',
                  }}
                  endIcon={<LooksTwoSharpIcon />}
                >
                  Deck
                </Button>
              ) : (
                <Button
                  size="small"
                  color="primary"
                  style={{
                    pointerEvents: 'none',
                  }}
                  endIcon={<LooksOneSharpIcon />}
                >
                  Deck
                </Button>
              )}
            </CardActions>
          </Card>
        );
      })}
    </div>
  );
}

export default ScheduleCard;
