import React, { useEffect, useRef } from 'react';
import Typed from 'typed.js';

const TypedText = () => {
  const typedElement = useRef(null);

  useEffect(() => {
    const options = {
      strings: ['Checking connection','Receiving Transmission','Frequency Tuning', 'Adjusting signal threshold...'],
      typeSpeed: 50,
      backSpeed: 10,
      loop: false,
    };

    const typedInstance = new Typed(typedElement.current, options);

    return () => {
      typedInstance.destroy();
    };
  }, []);

  return (
    <div
  style={{
    display: 'inline-block',
    whiteSpace: 'nowrap',
    fontSize: '12px', // Correct unit
    color: 'rgb(255, 102, 0)',
    textTransform: 'uppercase',
  }}
>
  <span ref={typedElement} />
</div>

  );
};

export default TypedText;
