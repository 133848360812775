import React from 'react';

import NowPlaying from '../components/NowPlaying';
import SongHistory from '../components/SongHistory';

function Home() {
  return (
    <div>
        <div style={{ marginBottom: '-100px' }}> {/* Adjust spacing here */}
          <NowPlaying />
        </div>
        <SongHistory />
    </div>
  );
}

export default Home;
