import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { FeedContext } from './FeedContext';

const token = process.env.REACT_APP_WSSR_API_TOKEN;

export const NowPlayingContext = createContext();

export const NowPlayingProvider = ({ children }) => {
  const [liveStreamData, setLiveStreamData] = useState(null);
  const [nowPlayingData, setNowPlayingData] = useState(null);
  const [liveStreamUrl, setLiveStreamUrl] = useState("https://broadcast.wssr.stream/listen/wssr/radio.mp3");
  const [streamUrl, setStreamUrl] = useState("https://broadcast.wssr.stream/listen/wssr/radio.mp3");
  const [progressColor, setProgressColor] = useState("rgba(255, 102, 0, 1)");
  const [station, setStation] = useState("wssr");
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLive, setIsLive] = useState(false); // New state for is_live
  const [currentTrackId, setCurrentTrackId] = useState(null);
  const [isLiveStream, setIsLiveStream] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false); // New state
  const [playbackLocked, setPlaybackLocked] = useState(false); // New state
  const [currentTrackIndex, setCurrentTrackIndex] = useState(null);
  const [trackQueue, setTrackQueue] = useState([]);
  const [loadingTrackId, setLoadingTrackId] = useState(null);
  const [currentStreamData, setCurrentStreamData] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [localElapsed, setLocalElapsed] = useState(0);
  let elapsedInterval = null; // Timer variable to track elapsed time updates
  const [sseLock, setSseLock] = useState(false); // 🛑 Lock SSE updates while manually fetching
  const [loadingDelay, setLoadingDelay] = useState(false);


  const { feed } = useContext(FeedContext);

  const startPlayback = () => {
    console.log('Playback started'); // Debugging log
    setIsPlaying(true);
  };

  const pauseTrack = () => {
    console.log('Playback paused'); // Debugging log
    setIsPlaying(false);
  };



  
  const toggleStreamUrl = () => {
    if (playbackLocked) {
      console.log("Playback locked, toggle skipped.");
      return;
    }

    console.log("Toggling stream...");
    setPlaybackLocked(true);
    const wasPlaying = isPlaying;

    setIsLoading(true);
    setProgressColor("#000");
    setNowPlayingData((prevData) => ({
      ...prevData,
      elapsed: 0,
    }));

    const newStation = station === "wssr" ? "wssr_node_2" : "wssr";
    const newStreamUrl =
      newStation === "wssr"
        ? "https://broadcast.wssr.stream/listen/wssr/radio.mp3"
        : "https://broadcast.wssr.stream/listen/wssr_node_2/radio.mp3";

    console.log(`Switching to ${newStation}`);
    setStation(newStation);
    setStreamUrl(newStreamUrl);
    setLiveStreamUrl(newStreamUrl);
    setCurrentTrackId(null);
    setIsLiveStream(true);

    setTimeout(() => {
      console.log("Stream URL updated...");
      setIsLoading(false);

      if (wasPlaying) {
        console.log("Resuming playback after stream switch...");
        setTimeout(() => {
          console.log("Setting playback to true...");
          setIsPlaying(true);
          setPlaybackLocked(false);
        }, 200);
      } else {
        setPlaybackLocked(false);
      }
    }, 300);
  };
  
  
  
  



  const playSpecificTrack = async (trackUrl, trackData, trackId, trackIndex) => {
    try {
      setLoadingTrackId(trackId);
  
      // Process track data
      const processedData = {
        id: trackId,
        title: trackData?.title || 'Unknown Title',
        artist: trackData?.artist || 'Unknown Artist',
        art: trackData?.art?.startsWith('http')
          ? trackData.art
          : `https://broadcast.wssr.stream${trackData.art || ''}`, // Prefix art URL if needed
        url: trackUrl,
        duration: trackData?.duration || 0,
        elapsed: 0,
        trackList: trackData?.trackList || [],
        path: trackData?.path?.startsWith('http')
          ? trackData.path
          : `https://broadcast.wssr.stream${trackData.path || ''}`, // Prefix path if needed
      };
  
      setTrackQueue((prevQueue) => {
        if (prevQueue.some((track) => track.path === trackId)) {
          return prevQueue; // Avoid duplicates
        }
        return [...prevQueue, { ...processedData, path: trackId }];
      });
  
      setStreamUrl(trackUrl);
      setNowPlayingData(processedData);
      setCurrentTrackId(trackId);
      setCurrentTrackIndex(trackIndex);
      setIsPlaying(true);
      setIsLiveStream(false);
    } catch (error) {
      console.error('Error during track playback preparation:', error);
    } finally {
      setLoadingTrackId(null);
    }
  };
  
  
  
  
  





// Utility function to clear revoked URLs
const clearRevokedBlobUrl = (track) => {
  if (track?.url?.startsWith('blob:')) {
    console.log('Clearing revoked BLOB URL:', track.url);
    URL.revokeObjectURL(track.url); // Ensure it is revoked
    delete track.url; // Remove it from the track object
  }
};









  

const playPreviousTrack = async (trackList) => {
  if (currentTrackIndex > 0) {
      const previousIndex = currentTrackIndex - 1;
      let previousTrack = trackList[previousIndex];

      try {
          // Set loadingTrackId for progress indication
          setLoadingTrackId(previousTrack.id || previousTrack.path);

          // Handle ShowTrackList-specific logic
          if (previousTrack.source === 'ShowTrackList' && (!previousTrack?.path || !previousTrack?.media?.path)) {
              const response = await axios.get(`https://broadcast.wssr.stream/api/station/1/file/${previousTrack.id}`, {
                  headers: { Authorization: token },
              });

              const fetchedData = response.data;
              previousTrack = {
                  ...previousTrack,
                  media: {
                      ...previousTrack.media,
                      path: fetchedData.path || '',
                      length: fetchedData.length || 0,
                      length_text: fetchedData.length_text || 'Unknown Length',
                  },
                  path: fetchedData.path || '',
              };

              trackList[previousIndex] = previousTrack; // Update trackList with enriched data
          }

          if (!previousTrack?.path) {
              throw new Error('Invalid previous track path');
          }

          // Generate or reuse blob URL for the track
          let blobUrl = previousTrack.url || '';
          if (!blobUrl.startsWith('blob:')) {
              const trackUrl = previousTrack.links?.download.startsWith('http')
                  ? previousTrack.links.download
                  : `https://broadcast.wssr.stream${previousTrack.links.download}`;

              if (!trackUrl) {
                  throw new Error('Failed to resolve URL for the previous track');
              }

              const response = await fetch(trackUrl, { headers: { Authorization: token } });
              const blob = await response.blob();
              blobUrl = URL.createObjectURL(blob);
              previousTrack.url = blobUrl; // Cache blob URL
          }

          // Update current track state and play
          setCurrentTrackIndex(previousIndex);
          setCurrentTrackId(previousTrack.id); // Update currentTrackId
          playSpecificTrack(blobUrl, {
              title: previousTrack.media?.title || previousTrack.title || 'Unknown Title',
              artist: previousTrack.media?.artist || previousTrack.description_short || 'Unknown Artist',
              art: previousTrack.media?.art
                  ? `https://broadcast.wssr.stream${previousTrack.media.art}`
                  : previousTrack.art,
              duration: previousTrack.media?.length || previousTrack.duration || 0,
              trackList,
          }, previousTrack.path, previousIndex);
      } catch (error) {
          console.error('Error playing previous track:', error);
      } finally {
          // Clear loading state
          setLoadingTrackId(null);
      }
  } else {
      console.error('No previous track available');
  }
};







  
  
  
  
  
const playNextTrack = async (trackList) => {
  if (currentTrackIndex < trackList.length - 1) {
      const nextIndex = currentTrackIndex + 1;
      let nextTrack = trackList[nextIndex];

      try {
          // Set loadingTrackId to show progress
          setLoadingTrackId(nextTrack.id || nextTrack.path);

          // Handle ShowTrackList-specific logic
          if (nextTrack.source === 'ShowTrackList' && (!nextTrack?.path || !nextTrack?.media?.path)) {
              const response = await axios.get(`https://broadcast.wssr.stream/api/station/1/file/${nextTrack.id}`, {
                  headers: { Authorization: token },
              });

              const fetchedData = response.data;
              nextTrack = {
                  ...nextTrack,
                  media: {
                      ...nextTrack.media,
                      path: fetchedData.path || '',
                      length: fetchedData.length || 0,
                      length_text: fetchedData.length_text || 'Unknown Length',
                  },
                  path: fetchedData.path || '',
              };

              trackList[nextIndex] = nextTrack; // Update trackList with enriched data
          }

          if (!nextTrack?.path) {
              throw new Error('Invalid next track path');
          }

          // Generate or reuse blob URL for the track
          let blobUrl = nextTrack.url || '';
          if (!blobUrl.startsWith('blob:')) {
              const trackUrl = nextTrack.links?.download.startsWith('http')
                  ? nextTrack.links.download
                  : `https://broadcast.wssr.stream${nextTrack.links.download}`;

              if (!trackUrl) {
                  throw new Error('Failed to resolve URL for the next track');
              }

              const response = await fetch(trackUrl, { headers: { Authorization: token } });
              const blob = await response.blob();
              blobUrl = URL.createObjectURL(blob);
              nextTrack.url = blobUrl; // Cache blob URL
          }

          // Update current track state and play
          setCurrentTrackIndex(nextIndex);
          setCurrentTrackId(nextTrack.id); // Update currentTrackId
          playSpecificTrack(blobUrl, {
              title: nextTrack.media?.title || nextTrack.title || 'Unknown Title',
              artist: nextTrack.media?.artist || nextTrack.description_short || 'Unknown Artist',
              art: nextTrack.media?.art
                  ? `https://broadcast.wssr.stream${nextTrack.media.art}`
                  : nextTrack.art,
              duration: nextTrack.media?.length || nextTrack.duration || 0,
              trackList,
          }, nextTrack.path, nextIndex);
      } catch (error) {
          console.error('Error playing next track:', error);
      } finally {
          // Clear loading state
          setLoadingTrackId(null);
      }
  } else {
      console.log('End of tracklist reached. No next track available.');
      setNowPlayingData((prevData) => ({
          ...prevData,
          elapsed: 0,
          //duration: 0,
      }));
      setIsPlaying(false);
  }
};












  

const fetchDirectoryTracks = async (path) => {
  try {
    const endpoint = `https://broadcast.wssr.stream/api/station/1/files/list?currentDirectory=${encodeURIComponent(path)}`;
    const response = await axios.get(endpoint, { headers: { Authorization: token } });
    return response.data.map((track) => ({
      ...track,
      fullArtUrl: track.media?.art ? `https://broadcast.wssr.stream${track.media.art}` : null,
    }));
  } catch (error) {
    console.error('Error fetching directory tracks:', error);
    throw error;
  }
};

  
  
  
  

useEffect(() => {
  if (sseLock) {
    console.log("SSE is temporarily locked. Skipping automatic SSE initialization.");
    return;
  }

  console.log("Initializing SSE for automatic updates...");

  const sseUri = `https://broadcast.wssr.stream/api/live/nowplaying/sse?cf_connect=${JSON.stringify({
    subs: { [`station:${station}`]: {} },
  })}`;

  const sse = new EventSource(sseUri);

  sse.onmessage = (e) => {
    if (sseLock) {
      console.log("SSE update ignored due to active lock.");
      return;
    }

    const data = JSON.parse(e.data);
    const newStreamData = data?.pub?.data?.np || null;

    if (newStreamData) {
      console.log("SSE update received.");

      // Always update liveStreamData
      setLiveStreamData(newStreamData.now_playing.song);
      setLiveStreamUrl(newStreamData.station?.listen_url || liveStreamUrl);

      // Prevent station switching if already correct
      if (newStreamData.station?.shortcode !== station) {
        console.log(`Station switching detected: ${station} → ${newStreamData.station?.shortcode}`);

        // Prevent unnecessary toggles between `wssr` and `wssr_node_2`
        if (
          (station === "wssr" && newStreamData.station?.shortcode === "wssr_node_2") ||
          (station === "wssr_node_2" && newStreamData.station?.shortcode === "wssr")
        ) {
          console.log("Ignoring redundant station switch.");
        } else {
          setStation(newStreamData.station?.shortcode);
          setStreamUrl(newStreamData.station?.listen_url || liveStreamUrl);
        }
      } else {
        console.log("Ignoring redundant station switch.");
      }

      // Only update nowPlayingData if in live stream mode
      if (isLiveStream && newStreamData.station?.shortcode === station) { // ✅ Only update if station matches
        console.log("Updating nowPlayingData from SSE (live mode).");
        setNowPlayingData({
          title: newStreamData.now_playing.song.title || "Unknown Title",
          artist: newStreamData.now_playing.song.artist || "Unknown Artist",
          art: newStreamData.now_playing.song.art || "https://placehold.co/60",
          duration: newStreamData.now_playing.duration || 0,
          elapsed: newStreamData.now_playing.elapsed || 0,
        });
      
        setProgressColor(station === "wssr" ? "rgba(255, 102, 0, 1)" : "rgba(146, 171, 207, 1)");
        setIsLoading(false);
        setInitialLoadComplete(true);
      }
    }
  };

  sse.onerror = () => {
    console.error("SSE encountered an error, closing...");
    sse.close();
  };

  return () => {
    console.log("Closing automatic SSE.");
    sse.close();
  };
}, [station, isLiveStream, sseLock]); // ✅ Includes `sseLock` to prevent flickering








const setLiveStreamNowPlayingData = async () => {
  console.log("setLiveStreamNowPlayingData manually triggered!");
  
  setSseLock(true); // 🛑 Temporarily lock SSE to prevent flickering

  const currentStation = await new Promise((resolve) => {
      setTimeout(() => resolve(station), 150); // Ensure React state is fully updated
  });

  console.log(`Fetching initial SSE update for station: ${currentStation}`);

  // Close any existing SSE connection first
  if (typeof window !== "undefined" && window.sseInstance) {
      console.log("Closing previous SSE instance before re-fetching...");
      window.sseInstance.close();
      window.sseInstance = null;
  }

  const sseUri = `https://broadcast.wssr.stream/api/live/nowplaying/sse?cf_connect=${JSON.stringify({
      subs: { [`station:${currentStation}`]: {} },
  })}`;

  console.log(`Opening temporary SSE connection for station: ${currentStation}`);
  const sse = new EventSource(sseUri);
  window.sseInstance = sse;

  sse.onmessage = (e) => {
      const data = JSON.parse(e.data);
      const newStreamData = data?.pub?.data?.np;

      if (newStreamData && newStreamData.station?.shortcode === currentStation) { 
          console.log(`Received initial nowPlayingData from SSE for: ${currentStation}`);
          setNowPlayingData({
              title: newStreamData.now_playing.song.title || "Unknown Title",
              artist: newStreamData.now_playing.song.artist || "Unknown Artist",
              art: newStreamData.now_playing.song.art || "https://placehold.co/60",
              duration: newStreamData.now_playing.duration || 0,
              elapsed: newStreamData.now_playing.elapsed || 0,
          });

          console.log("Closing SSE after fetching first update.");
          sse.close();
          window.sseInstance = null;

          setTimeout(() => {
              console.log("Re-enabling automatic SSE updates.");
              setSseLock(false); // ✅ Unlock SSE updates after a brief delay
          }, 500);
      }
  };

  sse.onerror = () => {
      console.error("SSE encountered an error, closing...");
      sse.close();
      window.sseInstance = null;
      setSseLock(false); // Ensure SSE is re-enabled even on error
  };
};





  
  
  
  
  
  
  



  return (
    <NowPlayingContext.Provider
      value={{
        station,
        liveStreamData,
        nowPlayingData,
        streamUrl,
        progressColor,
        toggleStreamUrl,
        startPlayback,
        playSpecificTrack,
        pauseTrack,
        isPlaying,
        setIsPlaying,
        currentTrackId,
        setCurrentTrackId,
        setStreamUrl,
        setNowPlayingData,
        isLive, 
        isLiveStream,
        setIsLiveStream,
        isLoading: isLoading || loadingDelay,
        setIsLoading,
        initialLoadComplete, 
        currentTrackIndex,
        setCurrentTrackIndex,
        playPreviousTrack,
        playNextTrack,
        trackQueue,
        setTrackQueue,
        loadingTrackId,
        setLoadingTrackId,
        fetchDirectoryTracks,
        setLiveStreamNowPlayingData,
      }}
    >
      {children}
    </NowPlayingContext.Provider>
  );
};
