import React, { useEffect, useState, useRef, useContext } from 'react';
import { Grid, Box, Typography, Skeleton, Fade, CircularProgress, IconButton, LinearProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FeedContext } from '../components/FeedContext';
import { NowPlayingContext } from '../components/NowPlayingProvider';
import PlayArrowSharpIcon from '@mui/icons-material/PlayArrowSharp';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { PiSoundcloudLogoDuotone } from "react-icons/pi";
import Bullet from '../components/Bullet';
import NewTracksCarousel from '../components/NewTracksCarousel';
import throttle from 'lodash/throttle';

const token = process.env.REACT_APP_WSSR_API_TOKEN;
const itemsPerPage = 10;

const formatDuration = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  return `${hours > 0 ? `${hours}h ` : ''}${minutes}m ${seconds}s`;
};

const fetchImageUrl = async (path, setImageUrls, setTrackCounts, setTotalDurations) => {
  try {
    const endpoint = `https://broadcast.wssr.stream/api/station/1/files/list?sort=timestamp&sortOrder=DESC&currentDirectory=${encodeURIComponent(path)}`;
    const response = await axios.get(endpoint, { headers: { Authorization: token } });

    const tracks = response.data;

    // Find the first track with path_short === 'cover.png'
    const coverTrack = tracks.find(track => track.path_short === 'cover.png');

    let imageUrl;
    if (coverTrack && coverTrack.links?.download) {
      try {
        const imageResponse = await axios.get(
          `https://broadcast.wssr.stream${coverTrack.links.download}`,
          {
            headers: { Authorization: token },
            responseType: 'blob' // Important for image blob download
          }
        );

        imageUrl = URL.createObjectURL(imageResponse.data);
      } catch (imageError) {
        console.error('Error fetching cover.png image:', imageError);
      }
    } else {
      // Fallback to first track's media.art if no "cover.png" found
      const firstTrack = tracks[0];
      imageUrl = firstTrack?.media?.links?.art
        ? `https://broadcast.wssr.stream${firstTrack.media.links.art}`
        : null;
    }

    if (imageUrl) {
      setImageUrls((prevUrls) => ({ ...prevUrls, [path]: imageUrl }));
    }

    // Filter out "cover.png" for trackCount and totalDuration calculations
    const filteredTracks = tracks.filter(item => item.path_short !== 'cover.png');
    const totalTracks = filteredTracks.length;
    const totalDuration = filteredTracks.reduce((acc, item) => acc + (item.media?.length || 0), 0);

    setTrackCounts((prevCounts) => ({ ...prevCounts, [path]: totalTracks }));
    setTotalDurations((prevDurations) => ({ ...prevDurations, [path]: totalDuration }));
  } catch (error) {
    console.error('Error fetching media art URL:', error);
  }
};




const Feed = () => {
  const {
    feed,
    setFeed,
    imageUrls,
    setImageUrls,
    trackCounts,
    setTrackCounts,
    totalDurations,
    setTotalDurations,
    loading,
    setLoading,
    page,
    setPage,
    hasMore,
    setHasMore,
  } = useContext(FeedContext);

  const { playSpecificTrack, currentTrackId, isPlaying, addToQueue, trackQueue, setCurrentTrackId, setIsPlaying, loadingTrackId, setLoadingTrackId } = useContext(NowPlayingContext);
  const [playAnimation, setPlayAnimation] = useState(null);
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(10);
  const observer = useRef(null);
  const loadingMore = useRef(false);

  const startLoadingAnimation = () => {
    setProgress(0);
    setBuffer(10);

    const interval = setInterval(() => {
      setProgress((prev) => Math.min(prev + Math.random() * 10, 100));
      setBuffer((prev) => Math.min(prev + Math.random() * 10, 100));
    }, 200);

    return interval;
  };

  const fetchData = async (newPage) => {
    if (!hasMore || loadingMore.current) return;
  
    try {
      loadingMore.current = true;
      if (newPage === 1) setLoading(true);
  
      const endpoint = `https://broadcast.wssr.stream/api/station/1/files/list?sort=timestamp&sortOrder=DESC&currentDirectory=Feed&rowCount=${itemsPerPage}&current=${newPage}`;
      const response = await axios.get(endpoint, { headers: { Authorization: token } });
  
      const newData = response.data.rows.filter(item => item.path_short !== 'cover.png');
  
      if (newPage >= response.data.total_pages) {
        setHasMore(false);
      }
  
      setFeed((prevFeed) => [
        ...prevFeed,
        ...newData.filter((item) => !prevFeed.some((existingItem) => existingItem.path === item.path)),
      ]);
  
      const newImageUrls = newData.reduce((acc, item) => {
        acc[item.path] = null;
        return acc;
      }, {});
      setImageUrls((prevUrls) => ({ ...prevUrls, ...newImageUrls }));
    } catch (error) {
      console.error('Error fetching data:', error);
      setHasMore(false);
    } finally {
      setLoading(false);
      loadingMore.current = false;
    }
  };
  

  const handlePlay = async (track, index, directoryTracks) => {
    if (isPlaying && currentTrackId === track.path) return;

    setLoadingTrackId(track.path);

    try {
        const mediaUrl = track.links?.download
            ? `https://broadcast.wssr.stream${track.links.download}`
            : null;

        if (mediaUrl) {
            const response = await axios.get(mediaUrl, {
                headers: { Authorization: token },
                responseType: 'blob',
            });

            const blobUrl = URL.createObjectURL(response.data);

            playSpecificTrack(
                blobUrl,
                {
                    title: track.media?.title || 'Unknown Title',
                    artist: track.media?.artist || 'Unknown Artist',
                    art: track.media?.art ? `https://broadcast.wssr.stream${track.media.art}` : null,
                    duration: track.media?.length || 0,
                    trackList: directoryTracks || feed,
                },
                track.path,
                index
            );
        }
    } catch (error) {
        console.error('Error fetching or preparing media:', error);
    }
};

  
  
  
  

  const observerCallback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const path = entry.target.getAttribute('data-path');
        fetchImageUrl(path, setImageUrls, setTrackCounts, setTotalDurations);
        observer.current.unobserve(entry.target);
      }
    });
  };

  useEffect(() => {
    observer.current = new IntersectionObserver(observerCallback, { threshold: 0.25, rootMargin: '400px' });

    feed.forEach((item) => {
      const element = document.querySelector(`[data-path="${item.path}"]`);
      if (element) observer.current.observe(element);
    });

    return () => observer.current.disconnect();
  }, [feed]);

  useEffect(() => {
    if (hasMore && feed.length === 0) {
      fetchData(page);
    }
  }, [page, hasMore]);

  useEffect(() => {
    // Force re-render when currentTrackId changes
  }, [currentTrackId]);
  

  const handleScroll = throttle(() => {
    if (!hasMore || loadingMore.current) return;

    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 500) {
      const nextPage = page + 1;
      setPage(nextPage);
      fetchData(nextPage);
    }
  }, 300);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    
    <Box sx={{ flexGrow: 1 }}>

      <NewTracksCarousel />

      <Grid container spacing={3} sx={{ marginBottom: '8rem' }}>
        {loading && !feed.length ? (
          Array.from(new Array(itemsPerPage)).map((_, index) => (
            <Grid item xs={12} key={index}>
              <Grid container spacing={2}>
                <Grid item xs={5} md={7}>
                  <Box sx={{ width: '100%', paddingTop: '100%', position: 'relative' }}>
                    <Skeleton variant="rectangular" sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} />
                  </Box>
                </Grid>
                <Grid item xs={7} md={5} display="flex" justifyContent="center" flexDirection="column">
                  <Skeleton variant="text" width="80%" height={30} />
                  <Skeleton variant="text" width="60%" height={25} sx={{ marginTop: '1rem' }} />
                  <Skeleton variant="text" width="50%" height={20} sx={{ marginTop: '1rem' }} />
                </Grid>
              </Grid>
            </Grid>
          ))
        ) : (
          feed.map((item, index) => (
            <Grid item xs={12} key={`${item.path}_${index}`}>
              <Grid container spacing={2}>
                <Grid item xs={5} md={7}>
                  <Box
                    sx={{
                      width: '100%',
                      paddingTop: '100%',
                      position: 'relative',
                      cursor: item.text === 'Directory' ? 'default' : 'pointer',
                      transform: playAnimation === item.path ? 'scale(1.1)' : 'scale(1)',
                      transition: 'transform 0.3s ease',
                      pointerEvents: isPlaying && currentTrackId === item.path ? 'none' : 'auto',
                    }}
                    data-path={item.path}
                    onClick={() => item.text !== 'Directory' && handlePlay(item, index, feed)}
                  >
                    {loadingTrackId === item.path && (
                      <LinearProgress
                        variant="buffer"
                        value={progress}
                        valueBuffer={buffer}
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          zIndex: 1,
                        }}
                      />
                    )}
                    {item.text === 'Directory' ? (
                      <Link to={`/feed/${encodeURIComponent(item.path)}`}>
                        {imageUrls[item.path] ? (
                          <Fade in={true} timeout={600}>
                            <img
                              src={imageUrls[item.path]}
                              alt={item.path_short || 'Feed Art'}
                              loading="lazy"
                              decode="async"
                              style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                boxShadow: '5px 5px 0px 0px rgba(255,102,0,1), 10px 10px 0px 0px rgba(25,35,48,1)',
                              }}
                            />
                          </Fade>
                        ) : (
                          <Skeleton
                            variant="rectangular"
                            sx={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                            }}
                          />
                        )}

                      </Link>
                    ) : (
                      <>
                        {!item.media.art && (
                          <Skeleton variant="rectangular" sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} />
                        )}
                        {item.media.art && (
                          <Fade in={true} timeout={600}>
                            <img
                              src={`https://broadcast.wssr.stream${item.media.art}`}
                              alt={item.media.title || 'Feed Art'}
                              loading="lazy"
                              decode="async"
                              style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                              }}
                            />
                          </Fade>
                        )}
                        {isPlaying && currentTrackId === item.path ? (
  <EqualizerIcon
    fontSize="large"
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'white',
      opacity: 0.8,
    }}
  />
) : (
  <PlayArrowSharpIcon
    fontSize="large"
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'white',
      opacity: 0.8,
    }}
  />
)}
</>
)}


{item.media?.custom_fields?.elink && (
                          <IconButton
                              component="a"
                              href={item.media.custom_fields.elink}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(event) => event.stopPropagation()} // Prevent playback on click
                              sx={{
                                clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
                                  position: 'absolute',
                                  bottom: 2,
                                  right: 2,
                                  padding: '8px',
                                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                  color: '#FF991C',
                                  borderRadius: '0%',
                                  transition: 'all 0.2s ease-in-out',
                                  '&:hover': {
                                      backgroundColor: 'rgba(0, 0, 0, 0.9)',
                                      padding: '12px',
                                      clipPath: 'polygon(0 20%, 80% 0, 100% 80%, 20% 100%)',
                                  }
                              }}
                          >
                              <PiSoundcloudLogoDuotone />
                          </IconButton>
                      )}


</Box>
</Grid>
<Grid item xs={7} md={5} display="flex" justifyContent="center" flexDirection="column">
  <Box>
    {item.text === 'Directory' ? (
      <Link to={`/feed/${encodeURIComponent(item.path)}`} style={{ textDecoration: 'none', color: 'inherit' }}>
        <Typography variant="subtitle1" sx={{ textOverflow: 'ellipsis' }}>
          {item.path_short}
        </Typography>
        <Typography variant="subtitle2" sx={{ textOverflow: 'ellipsis', color: '#999' }}>
          {trackCounts[item.path]
            ? `${trackCounts[item.path]} ${trackCounts[item.path] === 1 ? 'Track' : 'Tracks'}`
            : 'Loading...'}{Bullet}
          {totalDurations[item.path] !== undefined
            ? formatDuration(totalDurations[item.path])
            : 'Calculating...'}
        </Typography>
        {/* Updated Now Playing logic */}
        {isPlaying && currentTrackId?.startsWith(item.path) && (
          <Box display="flex" alignItems="center" mt={1}>
            <EqualizerIcon sx={{ fontSize: '14px', mr: 0.5, color: 'primary.main' }} />
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
              Now Playing
            </Typography>
          </Box>
        )}
      </Link>
    ) : (
      <>
        <Typography variant="subtitle1" sx={{ textOverflow: 'ellipsis' }}>
          {item.media?.title || 'No Title'}
        </Typography>
        <Typography variant="subtitle2" sx={{ textOverflow: 'ellipsis', color: '#999' }}>
          {item.media?.artist || 'Unknown Artist'}
        </Typography>
        <Typography variant="subtitle2" sx={{ textOverflow: 'ellipsis', color: '#999' }}>
          {formatDuration(item.media?.length || 0)}
        </Typography>
        {isPlaying && currentTrackId === item.path && (
          <Box display="flex" alignItems="center" mt={1}>
            <EqualizerIcon sx={{ fontSize: '14px', mr: 0.5, color: 'primary.main' }} />
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
              Now Playing
            </Typography>
          </Box>
        )}
      </>
    )}
  </Box>
</Grid>



              </Grid>
            </Grid>
          ))
        )}
        {loadingMore.current && (
          <Grid item xs={12} display="flex" justifyContent="center">
            <Box sx={{ marginTop: '2rem' }}>
              <CircularProgress thickness={3} />
            </Box>
          </Grid>
        )}
        {!hasMore && (
          <Grid item xs={12} display="flex" justifyContent="center">
            <Box sx={{ marginTop: '2rem' }}>
              <Typography sx={{ fontSize: '90px' }}>🎉</Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Feed;
