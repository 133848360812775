import React, { useContext, useEffect, useState } from 'react';
import { SwipeableDrawer, IconButton, Typography, Box, Skeleton, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { NowPlayingContext } from './NowPlayingProvider';
import CustomProgressBar from './CustomProgressBar';
import FlashingLiveIndicator from './FlashingLiveIndicator';
import { useSwipeable } from 'react-swipeable';

const AudioDrawer = ({ open, onOpen = () => {}, onClose, nowPlaying }) => {
  const {
    isPlaying,
    startPlayback,
    pauseTrack,
    nowPlayingData,
    isLiveStream,
    progressColor,
    isLoading,
    isLive,
    station,
    playPreviousTrack, playNextTrack, currentTrackIndex,
    loadingTrackId,
  } = useContext(NowPlayingContext);

  const [elapsedTime, setElapsedTime] = useState(nowPlayingData?.elapsed || 0);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (!isLiveStream) playNextTrack(nowPlayingData?.trackList || []);
    },
    onSwipedRight: () => {
      if (!isLiveStream) playPreviousTrack(nowPlayingData?.trackList || []);
    },
    preventScrollOnSwipe: true, // Prevent scrolling during swipe gestures
    trackMouse: true, // Allow mouse swipes for testing
  });

  useEffect(() => {
    let timer;

    if (isPlaying && !isLiveStream) {
      timer = setInterval(() => {
        setElapsedTime((prev) => Math.min(prev + 1, nowPlayingData?.duration || 0));
      }, 1000);
    } else {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [isPlaying, isLiveStream, nowPlayingData?.duration]);

  useEffect(() => {
    setElapsedTime(nowPlayingData?.elapsed || 0);
  }, [nowPlayingData?.elapsed]);

  const togglePlayback = () => {
    if (isPlaying) {
      pauseTrack();
    } else {
      startPlayback();
    }
  };

  const elapsedMinutes = Math.floor(elapsedTime / 60);
  const elapsedSeconds = String(Math.floor(elapsedTime % 60)).padStart(2, '0');

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      {...swipeHandlers} // Attach swipe handlers to the drawer
      PaperProps={{
        sx: {
          backgroundImage: nowPlaying?.art ? `url(${nowPlaying.art})` : 'none',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPositionX: `${(elapsedTime / (nowPlayingData?.duration || 1)) * 100}%`, // Dynamically shift background
          backgroundPositionY: 'center', // Keep the image vertically centered
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
          transition: 'background-position 0.1s linear', // Smooth movement
        },
      }}
    >
      {/* Progress Container Overlay */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          opacity: 0.4,
          zIndex: 1,
          '& .rhap_progress-container': {
            height: '100% !important',
            bottom: '0 !important',
            backgroundColor: '#363636 !important',
          },
        }}
      >
        <CustomProgressBar
          key="audio-drawer-progress-bar"
          initialElapsed={nowPlayingData?.elapsed || 0}
          duration={nowPlayingData?.duration || 1}
          color={
            isLiveStream
              ? isLive
                ? 'rgba(0, 0, 0, 0)' // isLiveStream is true and isLive is true
                : progressColor // isLiveStream is true and isLive is false
              : '#868686' // isLiveStream is false
          }
        />
      </Box>

      {/* Top Section */}
      <Box
        sx={{
          width: '100%',
          padding: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          zIndex: 2,
        }}
      >
        <Box>
          {isLoading ? (
            <>
              <Skeleton
                variant="text"
                sx={{
                  fontSize: '1.25rem',
                  backgroundColor: 'rgba(255,255,255,0.2)',
                  width: '90%',
                  minWidth: '150px',
                }}
              />
              <Skeleton
                variant="text"
                sx={{
                  fontSize: '1rem',
                  backgroundColor: 'rgba(255,255,255,0.2)',
                  width: '60%',
                  maxWidth: '300px',
                  marginTop: '4px',
                }}
              />
            </>
          ) : (
            <>
              <Typography
                variant="h6"
                sx={{
                  color: '#fff',
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  padding: '2px 8px',
                  borderRadius: 0,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '300px',
                }}
              >
                {nowPlaying?.title || 'Unknown Title'}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: '#999',
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  padding: '2px 8px',
                  borderRadius: 0,
                  marginTop: '4px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '300px',
                }}
              >
                {nowPlaying?.artist || 'Unknown Artist'}
              </Typography>
              {isLive && (
  
    <Box sx={{padding: '2px 0'}}>
      <FlashingLiveIndicator station={station} />
    </Box>
  
)}
            </>
          )}
        </Box>
        <IconButton
          onClick={onClose}
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: '#fff',
            borderRadius: 0,
          }}
        >
          <ExpandMoreIcon />
        </IconButton>
      </Box>






{/* Center Section */}
<Box
  sx={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: 400,
  }}
>
  {loadingTrackId ? (
    <CircularProgress thickness={3} />
  ) : (
    <>
      {/* Left Box */}
      {!isLiveStream && (
        <Box
          sx={{
            flex: 1,
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <IconButton
            onClick={() => playPreviousTrack(nowPlayingData?.trackList || [])}
            disabled={currentTrackIndex <= 0}
          >
            <SkipPreviousIcon sx={{ fontSize: 64 }} />
          </IconButton>
        </Box>
      )}
      
      {/* Center Box */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <IconButton
          onClick={togglePlayback}
          sx={{
            color: '#fff',
          }}
        >
          {isPlaying ? (
            <PauseCircleIcon sx={{ fontSize: 64 }} />
          ) : (
            <PlayCircleIcon sx={{ fontSize: 64 }} />
          )}
        </IconButton>
      </Box>

      {/* Right Box */}
      {!isLiveStream && (
        <Box
          sx={{
            flex: 1,
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <IconButton
            onClick={() => playNextTrack(nowPlayingData?.trackList || [])}
            disabled={
              !nowPlayingData?.trackList || 
              currentTrackIndex === null || 
              currentTrackIndex >= nowPlayingData.trackList.length - 1
            }
          >
            <SkipNextIcon sx={{ fontSize: 64 }} />
          </IconButton>
        </Box>
      )}
    </>
  )}
</Box>











      {/* On-Demand Track Progress 
      <Box
        sx={{
          position: 'absolute',
          bottom: '20px',
          left: '50%',
          transform: 'translateX(-50%)',
          color: '#fff',
          zIndex: 3,
          fontSize: '0.875rem',
          backgroundColor: '#373737 !important',
          padding: '5px 10px',
          borderRadius: '5px',
        }}
      >
        {isLiveStream
          ? `Live Stream`
          : `Progress: ${Math.floor((nowPlayingData?.elapsed || 0) / 60)}:${Math.floor(
              (nowPlayingData?.elapsed || 0) % 60
            )
              .toString()
              .padStart(2, '0')} / ${Math.floor((nowPlayingData?.duration || 0) / 60)}:${Math.floor(
              (nowPlayingData?.duration || 0) % 60
            )
              .toString()
              .padStart(2, '0')}`}
      </Box>*/}
    </SwipeableDrawer>
  );
};

export default AudioDrawer;
