import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Skeleton from '@mui/material/Skeleton';
import StationStatus from '../components/StationStatus';

function About() {
  const [loading, setLoading] = useState(true); // State to manage loading
  const generalContactUrl = 'https://forms.gle/4AJvTdm8tEVaL7ZZ7';
  const submitShowUrl = 'https://forms.gle/1t76mzPehGXxUBYWA';
  const deck1PlsUrl = 'https://wssr.stream/other/wssr_deck1.pls';
  const deck2PlsUrl = 'https://wssr.stream/other/wssr_deck2.pls';

  // Check localStorage for previous visits
  useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisitedAboutPage');
    
    if (hasVisited) {
      setLoading(false); // Skip skeleton if already visited
    } else {
      // Simulate a loading delay for the skeleton (you can adjust the delay)
      setTimeout(() => {
        setLoading(false);
        localStorage.setItem('hasVisitedAboutPage', 'true'); // Mark as visited
      }, 1500); // Adjust delay as needed
    }
  }, []);

  if (loading) {
    // Skeleton structure
    return (
      <div>
        <Skeleton variant="text" width="40%" height={50} />
        <Skeleton variant="text" width="80%" height={30} />
        <Skeleton variant="rectangular" width="100%" height={100} />
        <Skeleton variant="text" width="60%" height={30} />
        <Skeleton variant="rectangular" width="100%" height={50} />
        <Skeleton variant="text" width="50%" height={30} />
        <Skeleton variant="rectangular" width="100%" height={100} />
      </div>
    );
  }

  // The actual content to render when not loading
  return (
    <div>
      <h1>Stream Info</h1>
      <p>Spanning the Electronic Spectrum, Sector Seven Radio shows influence across all genres, from Dub, Jazz, R&B to much more.</p>

      <Stack direction="column" spacing={2}>
        <Button href={generalContactUrl} target="_blank" variant="text" style={{
                        borderRadius: "0%",
                        boxShadow: "inset 0 -77px 59px -40px rgba(0,0,0,.5)",
                      }}>General Contact Form</Button>
        <Button href={submitShowUrl} target="_blank" variant="text" style={{
                        borderRadius: "0%",
                        boxShadow: "inset 0 -77px 59px -40px rgba(0,0,0,.5)",
                      }}>Submit Your Show</Button>
      </Stack>

      <h1>How to Listen</h1>

      <p>
        You can listen right here on wssr.stream, via our <Link to="/partners">partner sites</Link>, or through VLC / Winamp / iTunes & other audio software.
      </p>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          '& > *': {
            m: 1,
          },
        }}
      >
        <ButtonGroup variant="text" aria-label="text button group">
          <Button href={deck1PlsUrl} target="_blank" download="DECK1.pls" startIcon={<CloudDownloadIcon />}>DECK 1 .PLS File</Button>
          <Button href={deck2PlsUrl} target="_blank" color="secondary" download="DECK2.pls" startIcon={<CloudDownloadIcon />}>DECK 2 .PLS File</Button>
        </ButtonGroup>
      </Box>

      <h1>Status</h1>

      <StationStatus />

      <Box display="flex" justifyContent="center" alignItems="center" sx={{ color: (theme) => theme.palette.primary.main, marginBottom: '8rem'}}><p>⊣ Sector Seven Radio Project ⊢</p></Box>
    </div>
  );
}

export default About;
