import React from "react";
import { Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// Styles for flashing animation
const styles = `
  @keyframes flash {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
  }
`;

const FlashStyle = () => (
  <style>
    {styles}
  </style>
);

const FlashingLiveIndicator = ({ station }) => {
  const theme = useTheme();

  // Determine colors based on station
  const borderColor = station === "wssr" ? theme.palette.primary.main : theme.palette.secondary.main;

  return (
    <Box
      sx={{
        border: `1px solid ${borderColor}`, // Border with dynamic color
        padding: "0px 4px", // Smaller padding
        display: "inline-block",
        borderRadius: "0", // No radius as specified
      }}
    >
      <FlashStyle />
      <Box display="flex" alignItems="center">
        {/* Smaller Flashing Square */}
        <Box
          sx={{
            width: 8, // Smaller width
            height: 8, // Smaller height
            backgroundColor: borderColor,
            animation: "flash 1s infinite",
            marginRight: 0.5, // Smaller gap between square and text
          }}
        />
        {/* Smaller Text */}
        <Typography
          variant="body2" // Smaller font size
          sx={{ color: borderColor, fontSize: '0.65rem' }}
        >
          Live Broadcast
        </Typography>
      </Box>
    </Box>
  );
};

export default FlashingLiveIndicator;
