import { createTheme } from '@mui/material/styles';

//$color-primary: #ff6600; // #24364e
//$color-secondary: #192330; // #92abcf
//$color-tertiary: #92abcf; // #3b506d
//$color-quaternary: #212c3d; // #212c3d
//$color-quinary: #3b506d; //
//$color-senary: #d5d8d8; //
//$color-base: #192330; // #192330

// LIGHT TO DARK
// #92abcf  146, 171, 207
// #3b506d
// #24364e
// #212c3d

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: "#ff6600",
    },
    secondary: {
      main: "#92abcf",
    },
    background: {
      default: '#000',
    },

  },
});

export default darkTheme;
