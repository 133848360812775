import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { styled, keyframes } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import StationSwitch from './StationSwitch';

// Updated keyframes for evenly spaced flashing pattern
const flashAnimation = keyframes`
  0% {
    opacity: 1;   /* Start fully visible */
  }
  70% {
    opacity: 1;   /* Remain fully visible for 10 seconds */
  }
  72.5% {
    opacity: 0;   /* First flash - fade out */
  }
  75% {
    opacity: 1;   /* First flash - fade in */
  }
  77.5% {
    opacity: 0;   /* Second flash - fade out */
  }
  80% {
    opacity: 1;   /* Second flash - fade in */
  }
  82.5% {
    opacity: 0;   /* Third flash - fade out */
  }
  85% {
    opacity: 1;   /* Third flash - fade in */
  }
  87.5% {
    opacity: 0;   /* Fourth flash - fade out */
  }
  90% {
    opacity: 1;   /* Fourth flash - fade in */
  }
  100% {
    opacity: 1;   /* Stay fully visible at the end */
  }
`;

const FlashSVG = styled('svg')(({ theme }) => ({
  width: '38.4px',  // Initial size
  height: '38.4px', // Initial size
  display: 'flex',
  marginRight: theme.spacing(1),
  fill: theme.palette.primary.main,  // Set fill color for the SVG
  cursor: 'pointer',  // Add pointer to indicate it's clickable
  opacity: 1,         // Start fully visible
  animation: `${flashAnimation} 30s infinite`,  // 30s total cycle, with 10s visible and evenly spaced flashes
  transition: 'transform 0.3s ease',  // Smooth scaling on hover
  '&:hover': {
    transform: 'scale(1.2)',  // Scale up by 20% on hover
  },
}));

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default function ElevateAppBar(props) {
  const navigate = useNavigate();  // React Router's hook to programmatically navigate
  const [checked, setChecked] = React.useState(false);  // Switch state

  // Handle click to redirect to the home page
  const handleClick = () => {
    navigate('/');  // Redirect to the home page
  };

  // Handle switch toggle
  const handleSwitchChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar className="blur border-bottom" sx={{ backgroundImage: 'none' }}>
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
              {/* Desktop version of SVG */}
              <Box onClick={handleClick}>
                <FlashSVG
                  sx={{ display: { xs: 'none', md: 'flex' } }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <rect x="4" y="4" width="16" height="16" />
                </FlashSVG>
              </Box>
              {/* Mobile version of SVG */}
              <Box onClick={handleClick}>
                <FlashSVG
                  sx={{ display: { xs: 'flex', md: 'none' } }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <rect x="4" y="4" width="16" height="16" />
                </FlashSVG>
              </Box>
            </Typography>

            {/* CustomSwitch on the right */}
            <StationSwitch checked={checked} onChange={handleSwitchChange} />
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
    </React.Fragment>
  );
}
