import { useCallback, useContext, useEffect, useState } from "react";
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim";
import { NowPlayingContext } from './NowPlayingProvider'; // Assuming context is available for deck state

const ParticleBackground = () => {
    const { station } = useContext(NowPlayingContext); // Access deck state through context
    const [particleOptions, setParticleOptions] = useState({
        color: "#f80",
        direction: "right",
    });
    const [fadeState, setFadeState] = useState("visible"); // "fading-out" | "hidden" | "fading-in"
    const [currentColor, setCurrentColor] = useState("#f80");

    const particlesInit = useCallback(async (engine) => {
        await loadSlim(engine);
    }, []);

    const particlesLoaded = useCallback(async (container) => {}, []);

    useEffect(() => {
        const newColor = station === "wssr_node_2" ? "#9aabcc" : "#f80";
        const newDirection = station === "wssr_node_2" ? "left" : "right";

        if (currentColor !== newColor) {
            // Step 1: Fade out
            setFadeState("fading-out");
            setTimeout(() => {
                // Step 2: Change color and hide
                setParticleOptions({ color: newColor, direction: newDirection });
                setCurrentColor(newColor);
                setFadeState("hidden");

                // Step 3: Fade in
                setTimeout(() => {
                    setFadeState("fading-in");
                    setTimeout(() => {
                        setFadeState("visible");
                    }, 300); // Time for fade-in
                }, 300); // Delay before fade-in starts
            }, 300); // Time for fade-out
        }
    }, [station, currentColor]);

    return (
        <div
            className="particle-container"
            style={{
                opacity: fadeState === "fading-out" ? 0 :
                         fadeState === "fading-in" ? 1 : 1,
                transition: "opacity 1s ease",
            }}
        >
            <Particles
                id="tsparticles"
                init={particlesInit}
                loaded={particlesLoaded}
                options={{
                    background: {
                        color: {}, // Not used here
                    },
                    fpsLimit: 120,
                    interactivity: {
                        events: {
                            onClick: {
                                enable: true,
                                mode: "push",
                            },
                            onHover: {
                                enable: true,
                                mode: "repulse",
                            },
                            resize: true,
                        },
                        modes: {
                            push: {
                                quantity: 4,
                            },
                            repulse: {
                                distance: 150,
                                duration: 0.4,
                            },
                        },
                    },
                    particles: {
                        color: {
                            value: particleOptions.color,
                        },
                        move: {
                            direction: particleOptions.direction,
                            enable: true,
                            random: true,
                            speed: 0.5,
                            straight: false,
                        },
                        number: {
                            density: {
                                enable: false,
                                area: 800,
                            },
                            value: 12,
                        },
                        opacity: {
                            value: 0.8,
                        },
                        shape: {
                            type: "square",
                        },
                        size: {
                            value: { min: 2, max: 6 },
                        },
                    },
                    detectRetina: true,
                }}
            />
        </div>
    );
};

export default ParticleBackground;
