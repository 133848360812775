// NowPlaying.js
import React, { useContext, useState, useEffect } from "react";
import { NowPlayingContext } from "./NowPlayingProvider";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { IconButton } from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";
import LooksOneSharpIcon from "@mui/icons-material/LooksOneSharp";
import LooksTwoSharpIcon from "@mui/icons-material/LooksTwoSharp";
import { PiSoundcloudLogoDuotone } from "react-icons/pi";
import FlashingLiveIndicator from "./FlashingLiveIndicator";
import BroadcastIndicator from "./BroadcastIndicator"; // Import BroadcastIndicator

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const NowPlaying = () => {
  const { liveStreamData, station, isLoading, setIsLoading, isLive } = useContext(NowPlayingContext);
const np = liveStreamData; // Always use live stream data

  const [fadeIn, setFadeIn] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");

  useEffect(() => {
    if (!isLoading) {
      setFadeIn(true);
    }
  }, [isLoading, station, JSON.stringify(liveStreamData)]); // 👈 Prevents unnecessary re-renders
  
  

  const handleOpen = (image) => {
    setOpen(true);
    setModalImage(image);
  };

  const handleClose = () => setOpen(false);

  const title = np?.title || "No title available";
  const artist =
    np?.title === "Live Broadcast" ? "" : np?.artist || "";
  const art = np?.art || "https://placehold.co/60";

  const buttonColor = station === "wssr" ? "primary" : "secondary";
  const deckButton = isLoading ? (
    <Box display="flex" alignItems="center" sx={{ cursor: "none" }}>
      <CircularProgress size={20} color={buttonColor} sx={{ marginLeft: 1 }} />
    </Box>
  ) : (
    <Button
      size="small"
      color={buttonColor}
      endIcon={station === "wssr" ? <LooksOneSharpIcon /> : <LooksTwoSharpIcon />}
    >
      Deck
    </Button>
  );

  useEffect(() => {
    if (!isLoading) return; // Prevent unnecessary updates
  
    setTimeout(() => {
      setIsLoading(false); // Add delay before hiding loading state
    }, 500); // Ensures visibility of CircularProgress
  }, [isLoading]);

  



  return (
    <div style={{ position: "relative" }}>
      <Box display="flex" alignItems="center">
        <h1>Now Playing</h1>
        {deckButton}
      </Box>
  
      {/* Main Content */}
      <Box sx={{ flexGrow: 1, position: "relative" }}>
        <Grid container spacing={3} sx={{ marginBottom: "8rem" }}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {/* Album Art Section */}
              <Grid item xs={5} md={7}>
                <Box sx={{ width: "100%", paddingTop: "100%", position: "relative" }}>
                  {isLoading ? (
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  ) : (
                    <Fade in={fadeIn} timeout={600}>
                      <img
                        src={art}
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          cursor: "pointer",
                        }}
                        alt={title}
                        onClick={() => handleOpen(art)}
                      />
                    </Fade>
                  )}

                  {np?.custom_fields?.elink && (
                          <IconButton
                              component="a"
                              href={np?.custom_fields?.elink}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(event) => event.stopPropagation()} // Prevent playback on click
                              sx={{
                                clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
                                  position: 'absolute',
                                  bottom: 2,
                                  right: 2,
                                  padding: '8px',
                                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                  color: '#FF991C',
                                  borderRadius: '0%',
                                  transition: 'all 0.2s ease-in-out',
                                  '&:hover': {
                                      backgroundColor: 'rgba(0, 0, 0, 0.9)',
                                      padding: '12px',
                                      clipPath: 'polygon(0 20%, 80% 0, 100% 80%, 20% 100%)',
                                  }
                              }}
                          >
                              <PiSoundcloudLogoDuotone />
                          </IconButton>
                      )}

                </Box>
              </Grid>
  
              {/* Song Info Section */}
              <Grid item xs={7} md={5} display="flex" justifyContent="center" flexDirection="column">
                {isLoading ? (
                  <>
                    <Skeleton variant="text" width="80%" height={30} />
                    <Skeleton variant="text" width="60%" height={25} />
                    <Skeleton variant="text" width="50%" height={20} />
                  </>
                ) : (
                  <>
                    <Fade in={fadeIn} timeout={600}>
                      <Typography variant="subtitle1" sx={{ textOverflow: "ellipsis" }}>
                        {title}
                      </Typography>
                    </Fade>
  
                    <Fade in={fadeIn} timeout={600}>
                      <Typography variant="subtitle1" sx={{ textOverflow: "ellipsis", color: "#999" }}>
                        {artist}
                      </Typography>
                    </Fade>
  
                    {isLive ? (
                      <Fade in={fadeIn} timeout={600}>
                        <Box>
                          <FlashingLiveIndicator station={station} />
                        </Box>
                      </Fade>
                    ) : (
                      <Fade in={fadeIn} timeout={600}>
                        <Box>
                          <BroadcastIndicator station={station} />
                        </Box>
                      </Fade>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
  
        {/* Loading Overlay - Always Present, But Hidden When Not Loading */}
        {isLoading && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0,0,0,0.5)", // Slight dark overlay
              zIndex: 10, // Ensure it's above everything
            }}
          >
            
          </Box>
        )}
      </Box>
  
      {/* Modal for Enlarged Album Art */}
      <Modal
        open={open}
        onClose={handleClose}
        disableAutoFocus={true}
        aria-labelledby="modal-image"
        aria-describedby="modal-image-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img src={modalImage} alt="Expanded Art" style={{ maxWidth: "90vw", maxHeight: "90vh" }} />
        </Box>
      </Modal>
    </div>
  );
  
};

export default NowPlaying;
