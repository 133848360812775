import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

function srcset(image, width, height, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${
      height * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function CustomImageList() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Detect small screens

  return (
    <Box sx={{ flexGrow: 1, p: 2, paddingBottom: '130px' }}> {/* Add paddingBottom to avoid overlap with navigation */}
      <h1>
        Partners
      </h1>
      <ImageList
        gap={1}
        cols={isSmallScreen ? 1 : 2} // Set to 1 column on small screens, 2 on larger
      >
        {itemData.map((item) => {
          const cols = item.featured ? 2 : 1;
          const rows = item.featured ? 2 : 1;

          return (
            <ImageListItem key={item.img} cols={isSmallScreen ? 1 : cols} rows={rows}>
              <img
                {...srcset(item.img, 250, 200, rows, cols)}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                sx={{
                  background:
                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                    'rgba(0,0,0,0.3) 40%, rgba(0,0,0,0) 100%)',
                }}
                title={item.title}
                position="top"
                actionIcon={
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    <IconButton
                      sx={{ color: 'white' }}
                      aria-label={`share ${item.title}`}
                    >
                      <ShareOutlinedIcon />
                    </IconButton>
                  </a>
                }
                actionPosition="left"
              />
            </ImageListItem>
          );
        })}
      </ImageList>
    </Box>
  );
}

const itemData = [
  {
    img: 'https://res.cloudinary.com/wssr/image/upload/v1729709259/live-radio-partner_uffspw.png',
    title: 'LiveRadio',
    featured: false,
    link: 'https://www.liveradio.ie/stations/wssr-sector-seven-radio',
  },
  {
    img: 'https://res.cloudinary.com/wssr/image/upload/v1729709259/streema-partner_m1gdvh.png',
    title: 'Streema',
    featured: false,
    link: 'https://streema.com/radios/Sector_Seven_Radio',
  },
  {
    img: 'https://res.cloudinary.com/wssr/image/upload/v1729709259/radio-net-partner_khrls9.png',
    title: 'Radio.net',
    featured: false,
    link: 'https://www.radio.net/s/wssrusa',
  },
  {
    img: 'https://res.cloudinary.com/wssr/image/upload/v1729709259/radio-garden-partner_aafp4g.png',
    title: 'Radio Garden',
    featured: false,
    link: 'https://radio.garden/listen/wssr-sector-seven-radio/5z4OImoS',
  },
  {
    img: 'https://res.cloudinary.com/wssr/image/upload/v1729709259/live-online-radio-partner_yrpbwk.png',
    title: 'Live Online Radio',
    featured: false,
    link: 'https://www.liveonlineradio.net/usa/wssr-sector-seven-radio.htm',
  },
];
